import { Anchor, Button, Center, Drawer, Grid, Group, Menu, NumberInput, Pagination, Paper, Stack, Table, Text, TextInput, useMantineTheme } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { useContext, useEffect, useState } from "react";
import axios from "../../utils/axios";
import { AuthContext } from "../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import { Heading } from "@aws-amplify/ui-react";
import swal from "sweetalert";

export default function Supply(){
    const theme = useMantineTheme();
    const { state } = useContext(AuthContext);
    const { height } = useViewportSize();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [size, setSize] = useState(5);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState(0);
    const [, setCount] = useState(0);
    const [id, setID] = useState("");
    const [previous, setPrevious] = useState("");
    const [current, setCurrent] = useState("");
    const [l, setL] = useState(false);
    const [r, setR] = useState(false);

    useEffect(() => {
        const config = {
            headers: {
                'Authorization': `Bearer ${state.userToken}`
            }
        }
        axios.post('/productions/get', {parent: state.userData._id, size: size, page: page}, config).then(function(res){
            if(res.status === 200){
                setData(res.data.data);
                setPagination(Math.ceil(res.data.count / size));
                setCount(res.data.count);
                setLoading(false);
            }
        }).catch(function(error){
            console.log(error);
            setLoading(false);
        });
    }, [size, page, r]);

    const saveUpdates = () => {
        if(previous.trim().length === 0){
            return false;
        }

        if(current.trim().length === 0){
            return false;
        }

        if(id.trim().length === 0){
            return false;
        }

        setL(true);

        axios.post("/productions/update", {
            id: id,
            update: {
                last_reading: parseFloat(previous),
                current_reading: parseFloat(current),
                production: parseFloat(current) - parseFloat(previous)
            }
        }, {headers: {'Authorization': `Bearer ${state.userToken}`}})
        .then(function(res){
            if(res.status === 200){
                toast.success("Success");
                setID("");
                setPrevious("");
                setCurrent("");
                setR(!r);
                setL(false);
            }
        }).catch(function(error){
            toast.error("An issue occured while updating");
            setL(false);
        })
    }

    const deleteSupply = (id) => {
        axios.post("/productions/delete", {id: id})
        .then(function(res){
            if(res.status === 200){
                toast.success("The supply value was removed");
                setR(!r);
            }
        })
        .catch(function(err){
            toast.error("An issue occured while deleting the supply value");
        })
    }

    const askDelete = (id) => {
        swal("Are you sure you want to delete the payment? This action cannot be reverted", {
            buttons: {
                cancel: 'Cancel',
                continue: {
                    text: "I'm sure, Delete",
                    value: 'continue'
                }
            }
        }).then(async (value) => {
            if(value === "continue"){
                deleteSupply(id);
            }
        })
    }
    return (
        <Grid>
            <Grid.Col sm={12} lg={12}>            
            {data.length === 0 && !loading ? (
                <>
                                <Center mt={"15%"}>
                    <img src={require("../../assets/waiting_hourglass.png")} />
                </Center>

            <Text align="center" mt={30}>Once supply values have been registered, they will appear here.</Text>
                </>
            ) : data.length === 0 && loading ? (
                <Center mt={"15%"}>
                    <FontAwesomeIcon icon={faSpinner} spin />
                </Center>
            ) : (
                <Paper p="md" withBorder>
                    <Group mb={20} position="right">
                        <Group noWrap>
                            <Menu width={220}>
                    <Menu.Target>
                        <Button>Bulk Actions</Button>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Item>Download all records</Menu.Item>
                    </Menu.Dropdown>
                </Menu>
                        </Group>
                        <Group position="right">
                            <NumberInput value={size} onChange={(val) => {setSize(val)}} min={1} />
                        </Group>
                    </Group>
                    <div style={{overflowX: 'auto'}}>
                <Table fontSize={"xs"} striped>
                    <thead>
                        <tr>
                            <th>DMA</th>
                            <th>Previous</th>
                            <th>Current</th>
                            <th>Supplied</th>
                            <th>Date</th>
                            <th>Image</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, idx) => {
                            return (
                                <tr key={`supply-${idx}`}>
                                    <td>{item.dma}</td>
                                    <td>{item.last_reading}</td>
                                    <td>{item.current_reading}</td>
                                    <td>{item.production}</td>
                                    <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                                    <td>
                                        <Anchor component="a" target="_blank" href={item.image}>image</Anchor>
                                    </td>
                                    <td>
                                        <Group>
                                        <Button size="xs" onClick={() => {
                                            setPrevious(""+item?.last_reading || "0");
                                            setCurrent(""+item?.current_reading || "0");
                                            setID(item._id);
                                        }}>Update</Button>
                                        <Button onClick={() => {askDelete(item._id)}} color="red" size="xs">Delete</Button>
                                        </Group>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                </div>
<Group mt={20} position="right">
<Pagination size="sm" page={page} onChange={setPage} total={pagination} siblings={0} spacing="xs" withControls radius="xs" />
</Group>
</Paper>
            )}

<Drawer opened={id.trim() !== ""} onClose={() => {setID("")}} position="right" title="" withCloseButton={false} padding="md" withOverlay={false} overlayProps={{ opacity: 0, blur: 0 }}>
        <Stack style={{height: height * 0.8}} justify="space-between">
            <div>
            <Heading level={6} marginBottom={20} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Update Supply</Heading>
            <Text mb={30}>Update the previous or current values of a master meter.</Text>
            <TextInput mt="md" label="Previous Reading" value={previous} onChange={(e) => {setPrevious(e.currentTarget.value)}} />
            <TextInput mt="md" label="Current Reading" value={current} onChange={(e) => {setCurrent(e.currentTarget.value)}} />
            </div>
        <Group mt={100}>
            <Button size='sm' radius={28} variant="default" onClick={() => {setID("")}}>Cancel</Button>
        <Button size='sm' radius={28} onClick={() => {saveUpdates()}} loading={l}>Update Supply</Button>
        </Group>
        </Stack>
      </Drawer>
</Grid.Col>
        </Grid>
    )
}