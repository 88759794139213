import { Text, Anchor, Group, Paper, Table, Center, Loader, Checkbox, Avatar, Button, Pagination, useMantineTheme, Space, Divider, Grid, Badge, Select, Menu, SimpleGrid, Notification, Alert } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { FileExport, InfoCircle,} from "tabler-icons-react";
import { AuthContext } from "../../App";
import axios from "../../utils/axios";
import { createWaterCost, createSewerCost, createWaterConsumptionBlocks, createSewerConsumptionBlocks } from "../../utils/createWaterCost";
import useStyles from "../customers/customer-list/customerList.style";
import { getDate, getPeriodEnding, } from "../../utils/getMonth";
import { Heading, SearchField } from "@aws-amplify/ui-react";
import { generateInvoice } from "../../utils/invoice2";
import { generateInvoiceNumber } from "../../utils/generate_invoice_number";
import { PDFDocument} from "pdf-lib";
import textClip from "../../utils/textClip";
import { useViewportSize } from "@mantine/hooks";
import { HEADER_HEIGHT } from "../dashboard/layout/header/header.style";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faSpinner} from '@fortawesome/free-solid-svg-icons';
import { downloadCSV } from "../../utils/downloadCSV";
import { downloadText } from "../../utils/downloadTextFile";

import ReactGA from "react-ga4";
import { DEFAULT_METER_READING_CYCLE } from "../../constants";
import diffInMonths from "../../utils/monthsToNow";
import getCycles from "../../utils/getCycles";
import { IconInfoCircle } from "@tabler/icons";

export default function PaymentsSummary(){
    const { classes, cx } = useStyles();
    const [customers, setCustomers] = useState([]);
    const { state, dispatch } = useContext(AuthContext);
    const [ready, setReady] = useState(false);
    const [selection, setSelection] = useState([]);
    const [action, setAction] = useState("");
    const [tarrif, ] = useState("all");
    const [name, setName] = useState("");
    const [name2, setName2] = useState("");
    const [, setConfigurredTarrif] = useState([]);
    const [size, setSize] = useState(25);
    const [scrolled, ] = useState(false);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState(0);
    const [tarrifs, setTarrifs] = useState([]);
    const [sewer_tarrifs, setSewerTarrifs] = useState([]);

    const [standing_charges, setStandingCharges] = useState(0);
    const [is_review_window, setIsReviewWindow] = useState(false);

    const [sortedData, setSortedData] = useState(customers);

    const [dates, setDates] = useState(null);
    const [startsIn, setStartsIn] = useState(null);
    const [endsIn, setEndsIn] = useState(null);

    const [meters_read, setReadMeters] = useState([]);
    const [total_read_meters, setTotalReadMeters] = useState(0);
    const [unread_meters, setUnreadMeters] = useState([]);
    const [total_unread_meters, setTotalUnread] = useState(0);

    const [toggle, setToggle] = useState(false);

    const [image_analysis, setImageAnalysis] = useState(false);

    const [image_analysis_amount, setImageAnalysisAmount] = useState(2);

    const [consumption, setConsumption] = useState([]);
    const [, setFilteredConsumption] = useState([]);

    const [billsPushed, setBillsPushed] = useState(false);
    const [, setBills] = useState([]);

    const [tasks_loading, setTasksLoading] = useState(false);

    // downloads
    const [read_checkboxes, setReadCheckboxes] = useState([]);
    const [unread_checkboxes, setUnreadCheckboxes] = useState([]);

    // payments
    const [payments, setPayments] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [payments_ready, setPaymentsReady] = useState(false);
    const [invoices_ready, setInvoicesReady] = useState(false);

    const [, setCurrentInvoices] = useState([]);

    const [, setProviders] = useState(null);

    let diff_in_months = diffInMonths(new Date(state.userData.createdAt));

    let [c,] = useState(getCycles(diff_in_months)[0].value);

    const [bills_pushed_arr, setBillsPushedArr] = useState([]);
    const [bills_pushed_count, setBillsPushedCount] = useState(0);

    const [partial, setPartial] = useState(0);
    const [pending, setPending] = useState(0);
    const [complete, setComplete] = useState(0);
    const [overpaid, setOverpaid] = useState(0);

    const [customerBal, setCustomerBal] = useState([]);

    const [paybills, setPaybills] = useState([]);

    const [send_reminder, setSendReminder] = useState(true);

    const [average_consumption, setAverageConsumption] = useState([]);

    const [critical_issues, setCriticalIssues] = useState([]);

    const [average_issues, setAverageIssues] = useState([]);

    const [ok, setOK] = useState([]);

    const [current_view, setCurrentView] = useState("all");

    const [due_dates, setDueDates] = useState(null);

    const [disconnection_fee, setDisconnectionFee] = useState(0);

    const [sms_configs, setSMSConfigs] = useState([]) // holder for sms configs, to be used to check if disconnected customers are billed.

    const [show_notification, setShowNotification] = useState(false);

    const [bulk_action, setBulkAction] = useState("Bills Delivery");

    const [reminder_status, setReminderStatus] = useState(false);

    const [sms_status, setSMSStatus] = useState(false);

    const [pdf_status, setPDFStatus] = useState(false);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          if (reminder_status || sms_status || pdf_status) {
            event.preventDefault();
            event.returnValue = '';
          }
        };
    
        if (reminder_status || sms_status || pdf_status) {
          window.addEventListener('beforeunload', handleBeforeUnload);
        } else {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        }
    
        // Cleanup on unmount
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, [reminder_status, sms_status, pdf_status]);

    useEffect(() => {
        axios.get("/disconnection-rates/getRate", {headers: {'Authorization': `Bearer ${state?.userToken}`}}).then(function(res){
            if(res.status === 200){
                if(res.data.data){
                    setDisconnectionFee(res.data.data.fee)
                }
            }
        }).catch(function(error){
            //toast.error("Could not fetch your disconnection configurations")
        });
    }, [])

    useEffect(() => {
        axios.get("/sms-configs/get", {headers: {'Authorization': `Bearer ${state.userToken}`}}).then(function(res){
          if(res.status === 200){
            let obj = res.data.data;
    
            if(obj){
              setSMSConfigs(obj?.configs);
            }
          }
        }).catch(function(error){
          console.log(error);
        })
    }, [])

    const checkIfDisconnectedCustomersReceiveBills = () => {

        var idx = sms_configs.findIndex((obj => obj.title === "Billing & Disconnected Customers"));

        if(idx === -1){
            return false;
        } else {
            return sms_configs[idx]?.checked;
        }
    }

    const fetchDueDates = () => {
        axios.get("/due-dates/due-dates", {headers: {'Authorization': `Bearer ${state.userToken}`}, params: {parent: state.userData._id}}).then(function(res){
            if(res.status === 200){
                setDueDates(res.data.data);
            }
        }).catch(function(error){
            //console.log(error);
        })
    } 
  
    useEffect(() => {
        fetchDueDates()
    }, []);

    function findNextDate(dayOfMonth) {
        const currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth() + 1; // Note: JavaScript months are 0-indexed (0 = January)
      
        // Check if the given dayOfMonth has already occurred in the current month
        if (currentDate.getDate() >= dayOfMonth) {
          // If it has, increment the month
          month++;
      
          // If we're in December, increment the year as well
          if (month > 12) {
            month = 1;
            year++;
          }
        }
      
        // Create a new Date object for the next occurrence
        //const nextDate = new Date(year, month - 1, dayOfMonth);
        const formattedNextDate = `${dayOfMonth}/${month}/${year}` // dd/mm/yyyy
      
        return formattedNextDate;
      }
      
    

    const getDueDatebyCategory = () => {
        //let idx = dates.findIndex((obj => obj.category === category));
        let result = ""
  
        if(due_dates?.category === "Fixed"){
            let x = parseInt(due_dates?.date)
            result = findNextDate(x)
        } else {
            const today = new Date();

            // Add the specified number of days to the date
            today.setDate(today.getDate() + parseInt(due_dates?.date || 14));
          
            // Format the date as dd/mm/yyyy
            result = today.toLocaleDateString("en-GB");  // Use "en-GB" for dd/mm/yyyy format
          
  
          //result = new Date(date.getTime()+(parseInt(due_dates?.date || 14) * 24 * 60 * 60 * 1000)).toLocaleDateString('en-US');
        }
  
        return result;
  
    }

    const getAverage = (acc) => {
        let idx = average_consumption.findIndex((obj) => obj._id === acc);

        if(idx !== -1){
            return Math.round(average_consumption[idx].averageConsumption);
        } else {
            return 0;
        }
    }

    useEffect(() => {
        ReactGA.send({hitType: "pageView", page: window.location.href})
    }, []);

    useEffect(() => {
        axios.get("/consumption/average", {headers: {'Authorization': `Bearer ${state.userToken}`}, params: {parent: state.userData._id}})
        .then(function(res){
            if(res.status === 200){
                setAverageConsumption(res.data.data)
            }
        }).catch(function(error){
            console.log(error);
        })
    }, [])

    useEffect(() => {
        let p = getCycles(diff_in_months)[0].value;

        axios.post("/master-invoices/invoices", {
            period: p,
            parent: state.userData._id,
            size: bills_pushed_count,
            page: 1
        }).then(function(res){
            if(res.status === 200){
                setCurrentInvoices(res.data.data)
            }
        }).catch(function(error){
            console.log(error);
        });

    }, [bills_pushed_count])

    useEffect(() => {
        axios.get("/sms-provider/configs", {headers: {'Authorization': `Bearer ${state.userToken}`}}).then(function(res){
          if(res.status === 200){
            setProviders(res.data.data);
          }
        }).catch(function(error){
          toast.error("An issue occured while fetching your data...");
        })
      }, [])

    useEffect(() => {
        try{
            axios.post("/short-codes/get-short-codes", {
                parent: state.userData._id
            }).then(function(res){
                if(res.status === 200){
                    setPaybills(res.data.data);
                }
            })
        } catch(error){
            console.log(error);
        }
    }, [])

    const getPaybill = (dma) => {
        if(paybills.length === 0){
            return "N/A"
        }

        if(paybills.length === 1 && paybills[0].dma === "*"){
            return paybills[0].short_code
        }

        let idx = paybills.findIndex((obj) => obj.dma === dma);

        if(idx === -1){
            return "N/A"
        } else {
            return paybills[idx].short_code
        }
    }

    useEffect(() => {
        const funcA = () => {
            var sum1 = 0;
            var sum2 = 0;
            var sum3 = 0;
            var sum4 = 0;
    
            for(let i=0; i<bills_pushed_arr.length; i++){
                let item = bills_pushed_arr[i];
    
                const real_time_defaulted = getDefaultedBalance(item.invoices.account_number); 
    
                const invoiced = parseFloat(item.invoices.total) + parseFloat(item.invoices.defaulted_balance);
        
                if((real_time_defaulted === invoiced) && (real_time_defaulted > 0)){
                    sum1++
                } else if ((real_time_defaulted < invoiced) && (real_time_defaulted > 0)) {
                    sum2++
                }  else if (real_time_defaulted === 0) {
                    sum3++
                }  else if (real_time_defaulted < 0 ) {
                    sum4++
            }
        }

        setPending(sum1);
        setPartial(sum2);
        setComplete(sum3);
        setOverpaid(sum4);
            
        }

        if(payments_ready && invoices_ready){
            funcA();
        }
        
    } , [bills_pushed_arr, payments_ready, invoices_ready]);
    
    useEffect(() => {
        axios.post("/master-invoices/invoices", {
            period: c,
            parent: state.userData._id,
            size: size,
            page: page
        }).then(function(res){
            if(res.status === 200){
                if(res.data.data.length > 0){
                    let filteredData = res.data.data.filter((obj) => obj.mode !== "disconnection" )
                    setBillsPushedArr(filteredData)
                    setPagination(Math.ceil(res.data.count / size));
                    setBillsPushedCount(res.data.count)
                }
            }
        }).catch(function(error){
            console.log(error);
        });

    }, [size, page, c, billsPushed, is_review_window, endsIn]);

    useEffect(() => {
        setPaymentsReady(false);

        const body = {
            parent: state.userData._id
        };

        axios.post("/payments/all", body).then(function(res){
            if(res.status === 200){
                setPayments(res.data.data);
                setPaymentsReady(true);
            }
        }).catch(function(error){
            console.log(error);
            setPaymentsReady(false);
        })
    }, [])

    useEffect(() => {
        setInvoicesReady(false);
        const body = {
            parent: state.userData._id
        };

        axios.post("/master-invoices/all", body).then(function(res){
            if(res.status === 200){
                setInvoices(res.data.data);
                setInvoicesReady(true);
            }
        }).catch(function(error){
            console.log(error);
            setInvoicesReady(false);
        })
    }, []);

    const sendRemindersAction = () => {
        setReminderStatus(true);

        var success_sum = 0;
        var failure_sum = 0;

        const toastID = toast.loading(`STEP 1/1: Sending sms to customers. This may take a while depending on the number of customers.`, {
            position: "bottom-right"
        });

        for(let i=0; i<customerBal.length; i++){
            let item = customerBal[i];

          axios.post("/sms/mobitech-sms", {
                to: item.mobile,
                sms: item.message,
                parent: state.userData._id,
                saveable: true,
                account: item.client_ref
            }).then(function(res){
                if(res.status === 200){
                    success_sum += 1;
                    if(i === customerBal.length - 1){

                        axios.post("/bulk-history/create", {
                            title: "Reminders",
                            description: `Bulk customer payment reminders initiated. ${customerBal.length} text messages were prepared for delivery. ${customerBal.length} text messages were delivered successfully. 0 text messages failed to deliver.`,
                            parent: state.userData._id
                        }, {
                            headers: {
                                'Authorization': `Bearer ${state.userToken}`
                            }
                        }).then(function(res){
                            if(res.status === 200){
                                toast.success("Your messages were delivered", {
                                    id: toastID
                                });

                                setReminderStatus(false);

                                setBulkAction("Reminders");

                                setShowNotification(true);
                            }
                        }).catch(function(err){
                            toast.error("An issue occured while sending an sms", {
                                id: toastID
                            });
                        })
                    }
                }
            }).catch(function(error){
                failure_sum += 1;
                toast.error("An issue occured while sending an sms", {
                    id: toastID
                });
            }) 
        } 
    }

    const sendReminders = () => {
        swal({
            title: "Warning",
            text: "Are you sure you want to send reminders now? This requires your SMS wallet to be loaded",
            buttons: {
                cancel: "Cancel",
                continue: {
                    text: "Send Reminders",
                    value: "cont"
                }
            }
        }).then(async (val) => {
            if(val === "cont"){
                sendRemindersAction();
            }
        })
    }

    const isPastOrDue = (inputDate) => {
        const dateParts = inputDate.split('/');
        const month = parseInt(dateParts[1]);
        const day = parseInt(dateParts[0]);
        const year = parseInt(dateParts[2]);
        const date = new Date(year, month - 1, day);

        const today = new Date();

        if(today > date){
            return "was";
        } else {
            return "is";
        }
    }

    function formatDate(inputDate) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const dateParts = inputDate.split('/');
        const month = parseInt(dateParts[1]);
        const day = parseInt(dateParts[0]);
        const year = parseInt(dateParts[2]);
        const date = new Date(year, month - 1, day);
        return date.toLocaleDateString("en-US", options); // US locality
    }

    const getDueDateForReminders = (acc) => {
        const filteredAndSortedData = invoices
            .filter(item => item.invoices.account_number === acc && (item.mode === "batch-download" || item.mode === "sms" || item.mode === "email" ))
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        return formatDate(filteredAndSortedData[0]?.invoices?.due_date || getDueDatebyCategory());
    }

    const isPastOrDueForReminders = (acc) => {
        const filteredAndSortedData = invoices
            .filter(item => item.invoices.account_number === acc && (item.mode === "batch-download" || item.mode === "sms" || item.mode === "email" ))
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        return isPastOrDue(filteredAndSortedData[0]?.invoices.due_date || getDueDatebyCategory());
    }

    useEffect(() => {
        if(payments_ready && invoices_ready && billsPushed && bills_pushed_arr.length > 0){
            axios.post("/customers/getCustomers", {parent: state.userData._id}).then(function(res){
                if(res.status === 200){
                    let arr = res.data.data;
    
                    let arr2 = [];
    
                    for(let i=0; i<arr.length; i++){
                        let item = arr[i];

                        //console.log(item);

                        let bal = getDefaultedBalance(item.account);
                        let dueDate = getDueDateForReminders(item.account);
                        let _isPastOrDue = isPastOrDueForReminders(item.account)

                        if(bal > 0){
                            // create sms
                            var message = "";

                            if(!item.disconnected){
                                message = `Hi ${item?.name?.split(" ")[0]},polite reminder.A/C:${item?.account},Bal Unpaid:KES:${bal} ${_isPastOrDue} due ${dueDate}.Paybill ${getPaybill(item?.dma)} Account ${getPaybill(item?.dma) === "247247" ? `500701#${item?.account}` : item?.account}.Pay to avoid KES ${disconnection_fee} disconnection fee.Helpline ${state.userData?.phonenumber}/${state.userData?.alternative_phonenumber}`;
                            } else {
                                message = `Hi ${item?.name?.split(" ")[0]},polite reminder to clear pending Bal:KES:${bal}.Paybill ${getPaybill(item?.dma)} A/C ${getPaybill(item?.dma) === "247247" ? `500701#${item?.account}` : item?.account}.Helpline ${state.userData?.phonenumber}/${state.userData?.alternative_phonenumber}`
                            }
                            
                            let chunk = {mobile: processPhoneNumber(item.phonenumber), message: message, client_ref: item.account}
                            arr2.push(chunk);
                        }
                    }
    
                    setCustomerBal(arr2);
                    setSendReminder(false);
                }
            }).catch(function(err){
                console.log(err);
                //toast.error("An issue occured while getting customers!");
            })
        }
    }, [payments_ready, invoices_ready])

    useEffect(() => {
        const body = {
          parent: state.userData._id
        }; 
      
        axios.post("/dates/get-dates", body)
          .then(function (res) {
            if (res.status === 200) {
              let dates = res.data.data;
              if(dates === null){
                dates = DEFAULT_METER_READING_CYCLE;
              }
              setDates(dates);
              let today = new Date();
              let startsOn = dates?.starts;
              let duration = dates?.duration;
              let review_window = dates?.reviewWindow;
      
              let currentMonth = today.getMonth() + 1;
              let currentYear = today.getFullYear();
      
              // Calculate the start and end dates of the current meter reading cycle
              let cycleStart;
              let cycleEnd;
              if (startsOn > today.getDate()) {
                // Cycle spans across two months
                if (currentMonth === 1) {
                    // If it's January, go back to December of the previous year
                    cycleStart = new Date(currentYear - 1, 11, startsOn);
                    cycleEnd = new Date(currentYear - 1, 11, startsOn + duration);
                } else {
                    cycleStart = new Date(currentYear, currentMonth - 2, startsOn);
                    cycleEnd = new Date(currentYear, currentMonth - 2, startsOn + duration);
                }
              } else {
                // Cycle starts and ends within the same month
                cycleStart = new Date(currentYear, currentMonth - 1, startsOn);
                cycleEnd = new Date(currentYear, currentMonth - 1, startsOn + duration + review_window);
              }
    

              setStartsIn(cycleStart);
              setEndsIn(cycleEnd);
      
              let now = new Date();
              let isMeterReadingCycle = now >= cycleStart && now <= cycleEnd;
      
              if (isMeterReadingCycle) {
                setIsReviewWindow(true);
                // Perform any additional actions specific to being in a meter reading cycle
              } else {
                setIsReviewWindow(false);
                // Perform any actions specific to not being in a meter reading cycle
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }, []);
      

    useEffect(() => {
        if(customers.length > 0){
            setReady(false);
            axios.post("/bills/utility-bills", {
                parent: state.userData._id,
                period: getDate(parseInt(dates?.starts))
            }).then(function(res){
                if(res.status === 200){
                    setBills(res.data.data);
                    // we assume that bills sent must be more than half the total number of customers
                    // else a need to push bills again.
                    if(res.data.count >= (customers.length * 0.5)){
                        setBillsPushed(true);
                    }
                    setReady(true);
                }
            }).catch(function(error){
                console.log(error);
                setReady(false);
            })
        }
    }, [customers]);


    const getMasterInvoices = async() => {
        return new Promise(async(resolve, reject) => {
            await axios.post("/bills/utility-bills", {
                parent: state.userData._id,
                period: getDate()
            }).then(function(res){
                if(res.status === 200){
                    if(res.data.count >= (customers.length * 0.5)){
                        setBillsPushed(true);
                        //setBills(res.data.data);
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }
            }).catch(function(error){
                resolve(true);
            });
        })
    }


    const pushInvoices = (sms=false) => {
        swal({
            title: "Warning",
            text: "Are you sure you want to push the bills now?",
            buttons: {
                cancel: "Cancel",
                continue: {
                    text: "Push Bills",
                    value: "continue"
                }
            }
        }).then(async (value) => {
            if(value === "continue"){
                pushInvoicesAction(sms);
            }
        })
    }   

    const pushInvoicesAction = async (sms=false) => {
        let now = new Date();

        const is_invoice_existing = await getMasterInvoices();

        if(now >= startsIn && now > endsIn && !is_invoice_existing){
            toast.success("Please wait for all the operations to go through. Don't refresh this page until all the bills have been delivered.", {
                duration: Infinity
            });

            let arr = [];

            let customerInvoices = [];

            for(let i=0; i<customers.length; i++){
                let item = customers[i];

                let disc = checkIfDisconnectedCustomersReceiveBills();

                if(!item.disconnected){
                    let str = await getPdfAsArrayBuffer(item._id, (i + 1));

                    arr.push(str.pdf);
                    customerInvoices.push(str.invoice);
                } else {
                    if(disc){
                        let str = await getPdfAsArrayBuffer(item._id, (i + 1));

                        arr.push(str.pdf);
                        customerInvoices.push(str.invoice);
                    }
                }
            }

            await saveCustomerInvoices(customerInvoices, sms ? "sms" : "batch-download", arr);

        }
    }

    function processPhoneNumber(phoneNumber) {
        // Remove any non-digit characters from the input phone number
        const cleanNumber = phoneNumber.replace(/\D/g, '');

        // Check if the cleaned number is at least nine digits long
        if (cleanNumber.length < 9) {
            // Handle the case where the phone number is too short
            return ;
        }
        // Take the last nine digits of the cleaned number
        const lastNineDigits = cleanNumber.slice(-9);

        // Add the country code "+254" to the last nine digits
        const formattedNumber = "254" + lastNineDigits;

        return formattedNumber;
    }

    const pushSMS = async (arr) => {
        setSMSStatus(true);

        var success_sum = 0;
        var failure_sum = 0;

        let id = toast.loading(`STEP 2/2: Sending sms to customers. This may take a while depending on the number of customers.`, {
            position: "bottom-right",
        });

        for(let i=0; i<arr.length; i++){
            let chunk = arr[i];

            await axios.post("/sms/mobitech-sms", {
                to: chunk.to,
                sms: chunk.message,
                parent: state.userData._id,
                saveable: true,
                account: chunk.client_ref
            }).then(function(res){
                if(res.status === 200){
                    //toast.success("")
                    success_sum += 0;

                    if( i === (arr.length - 1)){
                        axios.post("/bulk-history/create", {
                            title: "Bills Delivery",
                            description: `Bulk customer bill delivery initiated. ${arr.length} text messages were prepared for delivery. ${arr.length} text messages were delivered successfully. 0 text messages failed to deliver.`,
                            parent: state.userData._id
                        }, {
                            headers: {
                                'Authorization': `Bearer ${state.userToken}`
                            }
                        }).then(function(res){
                            if(res.status === 200){
                                toast.success("All the messages were delivered successfully.", {
                                    id: id,
                                    duration: Infinity
                                });

                                setSMSStatus(false);

                                setBulkAction("Bills Delivery");

                                setShowNotification(true);
                            }
                        }).catch(function(err){
                            toast.error("An issue occured while sending an sms", {
                                id: id,
                                duration: Infinity
                            });
                        })
                    }
                }
            }).catch(function(error){
                failure_sum += 0;
                console.log(error);
            })
        }
    }


    const saveCustomerInvoices = async (arr, mode, arr2) => {
        setPDFStatus(true);

        const toastId = toast.loading("STEP 1/2: Generating PDF invoices. This might take long depending on the number of customers.", {
            position: "bottom-right"
        });

        const smsArr = [];

        const masterInvoicesArr = [];

        const billsArr = [];

        for(let i=0; i<arr.length; i++){
            let item = arr[i];


            const body2 = {
                parent: state.userData._id,
                period: getCurrentMonthAndYear(1),
                invoices: item.pdf,
                account: item.customer.account_number,
                mode: mode
            }

            //console.log(body2);

            const body4 = {
                name: item.customer.name,
                account: item.customer.account,
                meter: item.customer.username,
                dma: item.customer.dma,
                status: item.pdf.account_status,
                last_reading: item.customer.previous_reading,
                current_reading: item.customer.last_reading,
                units: (item.customer.last_reading - item.customer.previous_reading),
                previous_balance: item.customer.previous_balance,
                water_cost: parseFloat(item.pdf.total_water_charges),
                has_sewer: item.pdf.has_sewer_charges,
                sewer_cost: parseFloat(item.pdf.total_Sewer_charges),
                total_cost: parseFloat(item.pdf.total),
                period: getCurrentMonthAndYear(1),
                bill_number: item.pdf.invoice_number,
                parent: state.userData._id
            }

           // console.log(body4);

            var name = item.customer.name;

            if(mode === "sms"){
                // create sms for customer
                if(item.pdf?.customer_phone !== "0700000000" && item.pdf?.customer_phone !== "0712345678" && processPhoneNumber(item.pdf?.customer_phone)){
                     var message = `Hi ${name.split(" ")[0]},A/C:${item?.customer?.account};PR:${item?.customer?.previous_reading || 0} CR:${item?.customer?.last_reading || 0}.Units:${(item?.customer?.last_reading || 0) - (item?.customer?.previous_reading || 0)}.${item?.pdf?.meter_rent !== 0 ? `Meter Rent:KES ${item?.pdf?.meter_rent}.` : ""}Bal B/F KES:${item?.pdf?.defaulted_balance}.Current KES:${item?.pdf?.total}.Bal KES:${parseFloat(item?.pdf?.defaulted_balance) + parseFloat(item?.pdf?.total)}.Due ${formatDate(item?.pdf?.due_date)}.Paybill ${getPaybill(item?.customer?.dma)} ${getPaybill(item?.customer?.dma) === "247247" ? `Account 500701#${item?.customer?.account}` : `Account ${item?.customer?.account}`}.${state.userData.username === "kragiawater@gmail.com" ? "Late payment fee Kes: 50" : `Reconnect KES:${disconnection_fee}`} after ${formatDate(item?.pdf?.due_date)}.Helpline ${state.userData?.phonenumber}/${state.userData?.alternative_phonenumber}`;
                     
                     smsArr.push({message: message, client_ref: item?.customer?.account, to: processPhoneNumber(item.pdf?.customer_phone)});
                } else {
                    console.log(item);
                }
            }

            masterInvoicesArr.push(body2);

            billsArr.push(body4);

            if(i === (arr.length - 1)){
                await axios.post("/master-invoices/create", {
                    isBulk: true,
                    invoices: masterInvoicesArr,
                }, {
                    headers: {
                        'Authorization': `Bearer ${state.userToken}`
                    }
                }).then(async function(res){
                    if(res.status === 200){
                        // bulk insert bills
                        await axios.post("/bills/create", {
                            isBulk: true,
                            bills: billsArr
                        }, {
                            headers: {
                                'Authorization': `Bearer ${state.userToken}`
                            }
                        }).then(function(res){
                            if(res.status === 200){
                                setPDFStatus(false);

                                pushSMS(smsArr);
        
                                mergePdfsAndDownload(arr2);
                
                                toast.success("Generating PDF invoices has finished successfully.", {
                                    id: toastId,
                                    duration: Infinity
                                });
                                setBillsPushed(true);
                            }
                        })
                    }
                }).catch(function(err){
                    console.log(err);
                });
            } 

            /*await axios.post("/master-invoices/create", body2).then(async function(res){
                if(res.status === 200){
                    await axios.post("/bills/create", body4).then(function(res){
                        if(res.status === 200){
                            if(i === (arr.length - 1)){
                                pushSMS(smsArr);

                                mergePdfsAndDownload(arr2);

                                toast.success("Generating and delivering invoices has finished successfully.", {
                                    id: toastId,
                                    duration: 10000
                                });
                                setBillsPushed(true);
                            }
                        }
                    })
                }
            }).catch(function(err){
                console.log(err);
            }); */
        } 

        //console.log(billsArr);
        //console.log(masterInvoicesArr);
        //console.log(smsArr);
    }

    useEffect(() => {
        const body = {
            parent: state.userData._id
        };   

        axios.post("/customers/getCustomers",body).then(({ data: { data: customers }, }) => {
            setReady(true);
            setCustomers(customers);
            setSortedData(customers);
            setPagination(Math.ceil(customers.length / size))
          }).catch(function (error) {
            setReady(true);
            toast.error(error.message);
          }).catch(function(error){
            console.log(error);
          })
    }, [])

    useEffect(() => {
        const config = {
            headers: {
                'Authorization': `Bearer ${state.userToken}`
            },
            params: {
                parent: state.userData._id
            }
        }
        axios.get("/consumption/get", config).then(function(res){
          if(res.status === 200){
            setConsumption(res.data.data)
          }
        }).catch(function(error){
          //toast.error("Something wrong is happening!");
          console.log(error);
        })
      }, []);

    useEffect(() => {
        setConfigurredTarrif([]);

        const body = {
            parent: state.userData._id
        };         
        axios.post("/tarrifs/gettarrifs", body).then(({ data: { data: x }, }) => {
            setTarrifs(x);
          let arr = [{ label: 'All', value: 'all' },];
          let arr2 = [];
          for (let i = 0; i < x.length; i++) {
            let item = x[i];
  
            let chunk = { label: item.tarrif, value: item.tarrif };
            arr.push(chunk);
            arr2.push(item.tarrif);
          }
  
          setConfigurredTarrif(arr);
        }).catch(function(error){
            console.log(error);
        })
    }, [])

    useEffect(() => {
        const body = {
            parent: state.userData._id
        }; 

        axios.post("/standing-charges/get-standing-charge", body).then(function (res) {
            if (res.status === 200) {
              let arr = res.data.data;
              let total_cost = 0;
              for (let i = 0; i < arr.length; i++) {
                total_cost += arr[i].cost
              }
              setStandingCharges(parseFloat(total_cost.toFixed(2)));
            }
          }).catch(function (error) {
            console.log(error);
          })
    }, []);

    useEffect(() => {
        const body = {
            parent: state.userData._id
        }; 

        axios.post("/sewer-tarrifs/gettarrifs", body).then(({ data: { data: x }, }) => {
            setSewerTarrifs(x);
          }).catch(function (error) {
            toast.error(error.message, {
              position: "bottom-right"
            })
          })
    }, []);

    const fetchTasks = () => {
        setTasksLoading(true);
        try {
            const body = {
                parent: state.userData._id
            };

            axios.post("/tasks/gettasks", body).then(function(res){
                let arr = [];
                let arr2 = [];

                let sum1 = 0;
                let sum2 = 0;

                for(let i=0; i<res.data.data.length; i++){
                    let item = res.data.data[i];
                    if(item.status !== "Overdue" && item.task === "0"){
                        arr.push({dma: item.dma, read_accounts: item.completeBuildings});
                        arr2.push({dma: item.dma, unread_accounts: item.building});
    
                        sum1 += item.completeBuildings.length;
                        sum2 += item.building.length
                    }
                }

                setReadMeters(arr);
                setUnreadMeters(arr2);

                setTotalReadMeters(sum1);
                setTotalUnread(sum2);

                setTasksLoading(false);
            }).catch(function(error){
                toast.error(error.message, {
                    position: "bottom-right"
                });
                setTasksLoading(false);
            })
        } catch(error){
            toast.error(error.message, {
                position: "bottom-right"
            });
            setTasksLoading(false);
        }
    }

    useEffect(() => {
        fetchTasks();
    }, []);

    useEffect(() => {
        let today = new Date();

        if(dates && consumption.length > 0){
            let startsOn = dates?.starts;

            let duration = dates?.duration;
    
            today.setDate(startsOn);
            today.setHours(0);
            today.setMinutes(0);
            today.setSeconds(0); // 0000hrs
          
            let endsIn = new Date(today.getTime() + (duration * 24 * 60 *60 * 1000));

            let arr = consumption.filter((item) => new Date(item.createdAt) >= today && new Date(item.createdAt) <= endsIn);

            setFilteredConsumption(arr);
        }
    }, [dates, consumption])


    const getDataCategory = (account, type) => {
        let idx = customers.findIndex((obj) => obj.account === account);

        let arr = consumption.filter((obj) => obj.meter === account);

        let latestCons = arr[arr.length - 1];

        let result = "";

        if(idx !== -1){
            let customer = customers[idx];

            switch(type){
                case "name":
                    result = customer.name;
                    break;
                case "status":
                    result = customer.disconnected ? "Disconnected" : "Connected";
                    break;
                case "meter":
                    result = customer.username;
                    break;
                case "previous":
                    result = latestCons?.prevReading;
                    break;
                case "current":
                    result = latestCons?.currReading;
                    break;
                case "image":
                    result = latestCons?.photo;
                    break;
                case "comment":
                    result = latestCons?.comment;
                    break;

                case "dma":
                    result = customer?.dma;
                    break;
                default:
                    //do nothing
            }
        }

        return result === null ? "N/A" : result;
    }

    const getDatebyCategory = (category) => {
        //let idx = dates.findIndex((obj => obj.category === category));
        
        let date = getDueDatebyCategory();

        return date;
    }

    const createCost = (qty, t) => {
        if(tarrifs.length === 0){
            return 0
        }

        /*
        // THIS WAS A BUG
        // for tarrifs with flat rate, 0 units is supposed to return the flat rate amount.
        if(qty <= 0){
            return 0; 
        } */

        let idx = tarrifs.findIndex((obj => obj.tarrif == t));
        if(idx === -1){
            return 0
        }
        let item = tarrifs[idx];
        let blocks = item.blocks;

        return createWaterCost(parseFloat(qty), blocks, standing_charges);
    }

    const createSwrCost = (qty, t) => {
        if(sewer_tarrifs.length === 0){
            return 0;
        }

        /*
        if(qty <= 0){
            return 0;
        } */

        let idx = sewer_tarrifs.findIndex((obj => obj.tarrif == t));
        if(idx === -1){
            return 0;
        }

        let item = sewer_tarrifs[idx];
        let blocks = item.blocks;

        return createSewerCost((((item?.percentage || 70) * 0.001) * parseFloat(qty)), blocks);
    }

    const createWaterBlocks = (qty, t) => {
        if(tarrifs.length === 0){
            return [["0", "0.00", "0.00"]]
        }

        let idx = tarrifs.findIndex((obj => obj.tarrif == t));
        if(idx === -1){
            return [["0", "0.00", "0.00"]]
        }

        let item = tarrifs[idx];
        let blocks = item.blocks;

        return createWaterConsumptionBlocks(parseFloat(qty), blocks);
    }

    const createSewerBlocks = (qty, t) => {
        if(sewer_tarrifs.length === 0){
            return [["0", "0.00", "0.00"]]
        }

        let idx = sewer_tarrifs.findIndex((obj => obj.tarrif == t));
        if(idx === -1){
            return [["0", "0.00", "0.00"]]
        }

        let item = sewer_tarrifs[idx];
        let blocks = item.blocks;

        return createSewerConsumptionBlocks(((item.percentage / 100) * parseFloat(qty)), blocks);
    }

    useEffect(() => {
        if(selection.length > 0){
            if(action !== ""){
                swal( 'Are you sure you want to continue?',{
                    buttons:{
                        cancel: 'Cancel',
                        send: {
                            text: 'Continue',
                            value: 'continue'
                        }
                    }
                }).then(async (value) => {
                    if(value === 'continue') {
                        if(action === "0"){
                            let loadId = toast.loading("Please wait...")
                            for(let i=0; i<selection.length; i++){
                                if(i === (selection.length - 1)){
                                    toast.success("Success", {
                                        id: loadId
                                    });
                                    setAction("");
                                    setSelection([]);
                                }

                                let id = selection[i];

                                let idx = customers.findIndex((obj => obj._id === id));

                                billByEmail(id, (idx + 1));
                            }
                        } else if(action === "1") {
                            let loadId = toast.loading("Please wait...")
                            for(let i=0; i<selection.length; i++){
                                if(i === (selection.length - 1)){
                                    toast.success("Success", {
                                        id: loadId
                                    });
                                    setAction("");
                                    setSelection([]);
                                }

                                let id = selection[i];

                                billBySMS(id);
                                
                            }
                        } else {
                            let loadId = toast.loading("Please wait...")
                            let arr = [];
                            for(let i=0; i<selection.length; i++){
                                if(i === (selection.length - 1)){
                                    toast.success("Success", {
                                        id: loadId
                                    });
                                    setAction("");
                                    setSelection([]);
                                }

                                let id = selection[i];

                                let idx = customers.findIndex((obj => obj._id === id));

                                let str = await getPdfAsArrayBuffer(id, (idx + 1));
                                arr.push(str);
                            }

                            mergePdfsAndDownload(arr);

                        }
                    } else {
                        setAction("");
                        setSelection([]);
                    }
                })
            }
        }
    }, [action])

    useEffect(() => {
        if(meters_read.length > 0){
            let arr1 = [];

            for(let i=0; i<meters_read.length; i++){
                let item = meters_read[i]?.read_accounts;

                for(let k=0; k<item.length; k++){
                    let it = item[k];

                    if(((parseInt(getDataCategory(it, "current")) - parseInt(getDataCategory(it, "previous"))) < 0)){
                        arr1.push(it);
                        continue;
                    } 

                }
            }

            setCriticalIssues(arr1);
        }
    }, [meters_read]);


    useEffect(() => {
        if(meters_read.length > 0 && average_consumption.length > 0){
            let arr2 = [];

            for(let i=0; i<meters_read.length; i++){
                let item = meters_read[i]?.read_accounts;
    
                for(let k=0; k<item.length; k++){
                    let it = item[k];
    
                    if(Math.abs((parseInt(getDataCategory(it, "current")) - parseInt(getDataCategory(it, "previous"))) - getAverage(it)) > 5){
                        arr2.push(it);
                        continue;
                    }
                }
            }
    
            setAverageIssues(arr2);
        }
}, [meters_read, average_consumption]);

useEffect(() => {
    if(meters_read.length > 0 && average_consumption.length > 0){
        let arr1 = [];
        let arr2 = [];
        let arr3 = [];
    
        for(let i=0; i<meters_read.length; i++){
            let item = meters_read[i]?.read_accounts;
    
            for(let k=0; k<item.length; k++){
                let it = item[k];
                arr3.push(it);
    
                if(((parseInt(getDataCategory(it, "current")) - parseInt(getDataCategory(it, "previous"))) < 0)){
                    arr1.push(it);
                    continue;
                } 
    
                if(Math.abs((parseInt(getDataCategory(it, "current")) - parseInt(getDataCategory(it, "previous"))) - getAverage(it)) > 5){
                    arr2.push(it);
                    continue;
                }
    
                if(((parseInt(getDataCategory(it, "current")) - parseInt(getDataCategory(it, "previous"))) > 0) && (Math.abs((parseInt(getDataCategory(it, "current")) - parseInt(getDataCategory(it, "previous"))) - getAverage(it)) <= 5)){
                    arr3.push(it);
                } 
            }
        }
    
        let mergedArray = arr1.concat(arr2);
    
        var okArr = arr3.filter((obj) => {
            return mergedArray.indexOf(obj) === -1
        });
    
        setOK(okArr);
    }
}, [meters_read, average_consumption]);

    const mergePdfsAndDownload = async (pdfsToMerge) => {
        const mergedPdf = await PDFDocument.create();
      
        const createInnerPromise = async (arrayBuffer) => {
          const pdf = await PDFDocument.load(arrayBuffer);
          return await mergedPdf.copyPages(pdf, pdf.getPageIndices());
        };
      
        const outerPromise = pdfsToMerge.map((arrayBuffer) => {
          const innerPromise = createInnerPromise(arrayBuffer);
          return innerPromise;
        });
      
        const resultOuterPromise = await Promise.all(outerPromise);
      
        resultOuterPromise.forEach((pageArray) => {
          pageArray.forEach((page) => {
            mergedPdf.addPage(page);
          });
        });
      
        const mergedPdfFile = (await (mergedPdf.save()));
    
        pushBillsToUtility(btoa(new Uint8Array(mergedPdfFile).reduce(function (data, byte) {
            return data + String.fromCharCode(byte);
        }, '')));
      }

      const pushBillsToUtility = (attachment) => {
        axios.post("/bills/bot-batch-bills", {
            company_name: state.userData.waterServiceName,
            company_mail: state.userData.username,
            last_reading_month: getLastReadingMonth(),
            current_reading_month: getCurrentReadingMonth(),
            reading_date: dates === null ? "24" : dates?.starts.toString(),
            arrayBuffer: attachment
        }).then(function(res){
            if(res.status === 200){
                //toast.success("The bills have been delivered success")
            }
        }).catch(function(error){
            console.log(error);
        })
    }

    const getDefaultedBalance = (acc) => {
        let billed = invoices.reduce((sum, obj) => {
            if(obj.invoices.account_number === acc){
                return sum + parseFloat(obj.invoices.total)
            }
            return sum;
        }, 0);

        let paid = payments.reduce((sum, obj) => {
            if(obj.account.trim() === acc){
                return sum + parseFloat(obj.transaction_amount);
            }
            return sum;
        }, 0);

        let defaultedAmount = (billed - paid);

        return defaultedAmount;
    }

    function getCurrentMonthAndYear(x=0) {
        const months = [
          "January", "February", "March", "April",
          "May", "June", "July", "August",
          "September", "October", "November", "December"
        ];
        
        const currentDate = new Date();

        var _currentMonth = currentDate.getMonth();

        var _currentYear = currentDate.getFullYear();

        if(_currentMonth === 0 && x === 1){
            _currentMonth = 11;
            _currentYear = _currentYear - 1;
        } else {
            _currentMonth = _currentMonth - x;
        }


        const currentMonth = months[_currentMonth];
      
        return `${currentMonth} ${_currentYear}`;
      }

      function getLastReadingDates() {
        const months = [
          "January", "February", "March", "April",
          "May", "June", "July", "August",
          "September", "October", "November", "December"
        ];
        
        const currentDate = new Date();
        const currentMonth = months[currentDate.getMonth() - 1];
        const currentYear = currentDate.getFullYear();
      
        return `${currentMonth} ${currentYear}`;
      }

      function getLastReadingMonth() {
        const months = [
          "January", "February", "March", "April",
          "May", "June", "July", "August",
          "September", "October", "November", "December"
        ];
        
        const currentDate = new Date();
        const currentMonth = months[currentDate.getMonth() - 1];
        const currentYear = currentDate.getFullYear();
      
        return `${currentMonth}`;
      }
      
      function getCurrentReadingMonth() {
        const months = [
          "January", "February", "March", "April",
          "May", "June", "July", "August",
          "September", "October", "November", "December"
        ];
        
        const currentDate = new Date();
        const currentMonth = months[currentDate.getMonth()];
        const currentYear = currentDate.getFullYear();
      
        return `${currentMonth}`;
      }
      

    const createOverriddenInvoices = () => {
        const loadID = toast.loading("Preparing the bills...");
        let arr = [];
        for(let i=0; i<customers.length; i++){
            if(i === customers.length - 1){
                toast.dismiss(loadID)
            }

            let obj = customers[i];

            const tala_b = obj?.account === "TALABOYSPRIMARY";

            const kusu = obj?.account === "PATRICKKUSU"

            let disc = checkIfDisconnectedCustomersReceiveBills();

            if(!disc){
                if(obj.disconnected){
                    continue; // we don't send invoices to discontinued customers;
                } else {

                    const units_consumed_factor = tala_b ? 20 : kusu ? 30 : 0;
    
                    let cost = tarrifs.length > 0 ? createCost(((obj.last_reading - obj.previous_reading) - units_consumed_factor), obj.tarrif) : 0;
    
                    const sewer_cost = obj.sewer && sewer_tarrifs.length > 0 ? createSwrCost(((obj.last_reading - obj.previous_reading) - units_consumed_factor), obj.tarrif) : 0;
    
                    const table2 = tarrifs.length > 0 ? createWaterBlocks(((obj.last_reading - obj.previous_reading) - units_consumed_factor), obj.tarrif) : [["0.00", "0.00", "0.00"]];
    
                    const table3 = obj.sewer && sewer_tarrifs.length > 0 ? createSewerBlocks(((obj.last_reading - obj.previous_reading) - units_consumed_factor), obj.tarrif) : [];
    
                    const total_amount_due = cost + sewer_cost + standing_charges + (obj?.previous_balance || 0) + (obj?.other_details?.meter_rent || 0) + (obj?.other_details?.standing_charges || 0);
    
                    let _obj = {
                        water_service_name: state.userData.waterServiceName,
                        company_phone: state.userData?.phonenumber,
                        company_email: state.userData?.username,
                        company_address: state.userData?.address || "N/A",
                        customer_name: obj?.name,
                        customer_address: obj?.address.length > 0 ? obj?.address.split(" ")[0] : "N/A",
                        bill_month: getCurrentMonthAndYear(),
                        customer_phone: obj.phonenumber,
                        invoice_number: generateInvoiceNumber(i),
                        account_number: obj?.account || "",
                        meter_number: obj.username,
                        due_date: getDatebyCategory("Invoice Defaulting"),
                        previous_bill_date: getLastReadingDates(),
                        defaulted_balance: ""+getDefaultedBalance(obj?.account),
                        meter_rent: obj?.other_details?.meter_rent || 0,
                        tables_data: [[obj.previous_reading !== null ? obj.previous_reading.toFixed(2) : "0", obj.last_reading !== null ? obj?.last_reading.toFixed(2) : "0", ((obj?.last_reading || 0) - (obj?.previous_reading || 0)).toFixed(2)]],
                        water_blocks: table2,
                        total_water_charges: cost.toFixed(2),
                        has_standing_charges: standing_charges > 0,
                        standing_charges: standing_charges,
                        sub_total_amount: total_amount_due.toFixed(2),
                        total: total_amount_due.toFixed(2),
                        vat_percentage: "0",
                        vat_amount: "0.00",
                        total_charges: total_amount_due.toFixed(2),
                        reading_date: startsIn.getDate().toString(),
                        last_reading_month: getLastReadingMonth(),
                        current_reading_month: getCurrentReadingMonth(),
                        current_year: startsIn.getFullYear().toString(),
                        has_sewer_charges: sewer_tarrifs.length > 0 && obj.sewer,
                        sewer_blocks: table3,
                        total_sewer_charges: obj.sewer && sewer_tarrifs.length > 0 ? sewer_cost.toFixed(2) : 0,
                        vat: "0",
                        output_type: "arraybuffer",
                        filename: obj.name + "-" + generateInvoiceNumber(i),
                        return_jspdfdoc_obj: true,
                        account_status: obj.disconnected ? "Disconnected" : "Connected"
                    }
    
                    let invoice_generated = generateInvoice(_obj);
    
                    let invoiceData = {
                        pdf: _obj,
                        customer: obj
                    }
    
                    arr.push(invoiceData);
                }
            } else {
                const units_consumed_factor = tala_b ? 20 : kusu ? 30 : 0;

                let cost = tarrifs.length > 0 ? createCost(((obj.last_reading - obj.previous_reading) - units_consumed_factor), obj.tarrif) : 0;

                const sewer_cost = obj.sewer && sewer_tarrifs.length > 0 ? createSwrCost(((obj.last_reading - obj.previous_reading) - units_consumed_factor), obj.tarrif) : 0;

                const table2 = tarrifs.length > 0 ? createWaterBlocks(((obj.last_reading - obj.previous_reading) - units_consumed_factor), obj.tarrif) : [["0.00", "0.00", "0.00"]];

                const table3 = obj.sewer && sewer_tarrifs.length > 0 ? createSewerBlocks(((obj.last_reading - obj.previous_reading) - units_consumed_factor), obj.tarrif) : [];

                const total_amount_due = cost + sewer_cost + standing_charges + (obj?.previous_balance || 0) + (obj?.other_details?.meter_rent || 0) + (obj?.other_details?.standing_charges || 0);

                let _obj = {
                    water_service_name: state.userData.waterServiceName,
                    company_phone: state.userData?.phonenumber,
                    company_email: state.userData?.username,
                    company_address: state.userData?.address || "N/A",
                    customer_name: obj?.name,
                    customer_address: obj?.address.length > 0 ? obj?.address.split(" ")[0] : "N/A",
                    bill_month: getCurrentMonthAndYear(),
                    customer_phone: obj.phonenumber,
                    invoice_number: generateInvoiceNumber(i),
                    account_number: obj?.account || "",
                    meter_number: obj.username,
                    due_date: getDatebyCategory("Invoice Defaulting"),
                    previous_bill_date: getLastReadingDates(),
                    defaulted_balance: ""+getDefaultedBalance(obj?.account),
                    meter_rent: obj?.other_details?.meter_rent || 0,
                    tables_data: [[obj.previous_reading !== null ? obj.previous_reading.toFixed(2) : "0", obj.last_reading !== null ? obj?.last_reading.toFixed(2) : "0", ((obj?.last_reading || 0) - (obj?.previous_reading || 0)).toFixed(2)]],
                    water_blocks: table2,
                    total_water_charges: cost.toFixed(2),
                    has_standing_charges: standing_charges > 0,
                    standing_charges: standing_charges,
                    sub_total_amount: total_amount_due.toFixed(2),
                    total: total_amount_due.toFixed(2),
                    vat_percentage: "0",
                    vat_amount: "0.00",
                    total_charges: total_amount_due.toFixed(2),
                    reading_date: startsIn.getDate().toString(),
                    last_reading_month: getLastReadingMonth(),
                    current_reading_month: getCurrentReadingMonth(),
                    current_year: startsIn.getFullYear().toString(),
                    has_sewer_charges: sewer_tarrifs.length > 0 && obj.sewer,
                    sewer_blocks: table3,
                    total_sewer_charges: obj.sewer && sewer_tarrifs.length > 0 ? sewer_cost.toFixed(2) : 0,
                    vat: "0",
                    output_type: "arraybuffer",
                    filename: obj.name + "-" + generateInvoiceNumber(i),
                    return_jspdfdoc_obj: true,
                    account_status: obj.disconnected ? "Disconnected" : "Connected"
                }

                let invoice_generated = generateInvoice(_obj);

                let invoiceData = {
                    pdf: _obj,
                    customer: obj
                }

                arr.push(invoiceData);
            }
        }

        saveOverridenInvoices(arr);
    }

    const saveOverridenInvoices = async (arr) => {
        setPDFStatus(true);

        let toastId = toast.loading("STEP 1/2: Generating PDF invoices. This might take long depending on the number of customers.", {
            position: "bottom-right"
        });

        const smsArr = [];

        const masterInvoicesArr = [];

        const billsArr = [];

        for(let i=0; i<arr.length; i++){
            let item = arr[i];


            const body2 = {
                parent: state.userData._id,
                period: getCurrentMonthAndYear(),
                invoices: item.pdf,
                account: item.customer.account,
                mode: "sms"
            }

            
            const body4 = {
                name: item.customer.name,
                account: item.customer.account,
                meter: item.customer.username,
                dma: item.customer.dma,
                status: item.pdf.account_status,
                last_reading: item.customer.previous_reading,
                current_reading: item.customer.last_reading,
                units: (item.customer.last_reading - item.customer.previous_reading),
                previous_balance: item.customer.previous_balance,
                water_cost: parseFloat(item.pdf.total_water_charges),
                has_sewer: item.pdf.has_sewer_charges,
                sewer_cost: parseFloat(item.pdf.total_Sewer_charges),
                total_cost: parseFloat(item.pdf.total),
                period: getCurrentMonthAndYear(),
                bill_number: item.pdf.invoice_number,
                parent: state.userData._id
            }

            var name = item.customer.name


            var message = `Hi ${name.split(" ")[0]},A/C:${item?.customer?.account};PR:${item?.customer?.previous_reading || 0} CR:${item?.customer?.last_reading || 0}.Units:${(item?.customer?.last_reading || 0) - (item?.customer?.previous_reading || 0)}.${item?.pdf?.meter_rent !== 0 ? `Meter Rent:KES ${item?.pdf?.meter_rent}.` : ""}Bal B/F KES:${item?.pdf?.defaulted_balance}.Current KES:${item?.pdf?.total}.Bal KES:${parseFloat(item?.pdf?.defaulted_balance) + parseFloat(item?.pdf?.total)}.Due ${formatDate(item?.pdf?.due_date)}.Paybill ${getPaybill(item?.customer?.dma)} ${getPaybill(item?.customer?.dma)=== "247247" ? `Account 500701#${item?.customer?.account}` : `Account ${item?.customer?.account}`}.${state.userData.username === "kragiawater@gmail.com" ? "Late payment fee KES: 50" : `Reconnect KES:${disconnection_fee}`} after ${formatDate(item?.pdf?.due_date)}.Helpline ${state.userData?.phonenumber}/${state.userData?.alternative_phonenumber}`;

            
            if(item.pdf?.customer_phone !== "0700000000" && item.pdf?.customer_phone !== "0712345678" && processPhoneNumber(item.pdf?.customer_phone)){
                smsArr.push({message: message, client_ref: item?.customer?.account, to: processPhoneNumber(item.pdf?.customer_phone)});

            } 

            masterInvoicesArr.push(body2);

            billsArr.push(body4);

            if(i === (arr.length - 1)){
                await axios.post("/master-invoices/create", {
                    isBulk: true,
                    invoices: masterInvoicesArr,
                }, {
                    headers: {
                        'Authorization': `Bearer ${state.userToken}`
                    }
                }).then(async function(res){
                    if(res.status === 200){
                        // bulk insert bills
                        await axios.post("/bills/create", {
                            isBulk: true,
                            bills: billsArr
                        }, {
                            headers: {
                                'Authorization': `Bearer ${state.userToken}`
                            }
                        }).then(function(res){
                            if(res.status === 200){
                               setPDFStatus(false);
                               
                                pushSMS(smsArr);
        
                                //mergePdfsAndDownload(arr);
                
                                toast.success("Generating and delivering invoices has finished successfully.", {
                                    id: toastId,
                                    duration: 10000
                                });
                                setBillsPushed(true);
                            }
                        })
                    }
                }).catch(function(err){
                    console.log(err);
                });
            } 

            /*await axios.post("/master-invoices/create", body2).then(async function(res){
                if(res.status === 200){
                    await axios.post("/bills/create", body4).then(function(res){
                        if(res.status === 200){
                            if(i === (arr.length - 1)){

                                pushSMS(smsArr);

                                toast.success("Generating and delivering invoices has finished successfully.", {
                                    id: toastId,
                                    duration: 10000
                                });
                                //setBillsPushed(true);
                            }
                        }
                    }).catch(function(error){
                        console.log(error);
                    })
                }
            }).catch(function(err){
                console.log(err);
            }); */

        }

        //console.log(billsArr);
        //console.log(masterInvoicesArr);
        //console.log(smsArr);
    }

    const overrideSystemInvoicing = () => {
        swal({
            title: "Warning",
            text: "Are you sure you want to push the bills now?",
            buttons: {
                cancel: "Cancel",
                continue: {
                    text: "Push Bills",
                    value: 'continue'
                }
            }
        }).then(async (value) => {
            if(value === "continue"){
                createOverriddenInvoices();
            }
        })
    }


    const getPdfAsArrayBuffer = async (id, index) => {
        let idx = customers.findIndex((obj => obj._id === id));

        if(idx !== -1){
            let obj = customers[idx];

            const tala_b = obj?.account === "TALABOYSPRIMARY";

            const kusu = obj?.account === "PATRICKKUSU"

            const units_consumed_factor = tala_b ? 20 : kusu ? 30 : 0;

            let cost = tarrifs.length > 0 ? createCost(((obj.last_reading - obj.previous_reading) - units_consumed_factor), obj.tarrif) : 0;

            const sewer_cost = obj.sewer && sewer_tarrifs.length > 0 ? createSwrCost(((obj.last_reading - obj.previous_reading) - units_consumed_factor), obj.tarrif) : 0;

            const table2 = tarrifs.length > 0 ? createWaterBlocks(((obj.last_reading - obj.previous_reading) - units_consumed_factor), obj.tarrif) : [["0.00", "0.00", "0.00"]];

            const table3 = obj.sewer && sewer_tarrifs.length > 0 ? createSewerBlocks(((obj.last_reading - obj.previous_reading) - units_consumed_factor), obj.tarrif) : [];

            const total_amount_due = cost + sewer_cost + standing_charges + (obj?.previous_balance || 0) + (obj?.other_details?.meter_rent || 0) + (obj?.other_details?.standing_charges || 0);

            let invoice_generated = generateInvoice({
                water_service_name: state.userData.waterServiceName,
                company_phone: state.userData?.phonenumber,
                company_email: state.userData?.username,
                company_address: state.userData?.address || "N/A",
                customer_name: obj?.name,
                customer_address: obj?.address.length > 0 ? obj?.address.split(" ")[0] : "N/A",
                bill_month: getDate(),
                customer_phone: obj.phonenumber,
                invoice_number: generateInvoiceNumber(index),
                account_number: obj?.account || "",
                meter_number: obj.username,
                due_date: getDatebyCategory("Invoice Defaulting"),
                previous_bill_date: getPeriodEnding(),
                defaulted_balance: getDefaultedBalance(obj?.account).toFixed(2),
                meter_rent: obj?.other_details?.meter_rent || 0,
                tables_data: [[obj.previous_reading !== null ? obj.previous_reading.toFixed(2) : "0", obj.last_reading !== null ? obj?.last_reading.toFixed(2) : "0", ((obj?.last_reading || 0) - (obj?.previous_reading || 0)).toFixed(2)]],
                water_blocks: table2,
                total_water_charges: cost.toFixed(2),
                has_standing_charges: standing_charges > 0,
                standing_charges: obj?.other_details?.standing_charges || standing_charges,
                sub_total_amount: total_amount_due.toFixed(2),
                total: total_amount_due.toFixed(2),
                vat_percentage: "0",
                vat_amount: "0.00",
                total_charges: total_amount_due.toFixed(2),
                reading_date: startsIn.getDate().toString(),
                last_reading_month: getLastReadingMonth(),
                current_reading_month: getCurrentReadingMonth(),
                current_year: startsIn.getFullYear().toString(),
                has_sewer_charges: sewer_tarrifs.length > 0 && obj.sewer,
                sewer_blocks: table3,
                total_sewer_charges: obj.sewer && sewer_tarrifs.length > 0 ? sewer_cost.toFixed(2) : 0,
                vat: "0",
                output_type: "arraybuffer",
                filename: obj.name + "-" + generateInvoiceNumber(index),
                return_jspdfdoc_obj: true,
                account_status: obj.disconnected ? "Disconnected" : "Connected"
            });

            let invoiceData = {
                pdf: {
                water_service_name: state.userData.waterServiceName,
                company_phone: state.userData?.phonenumber,
                company_email: state.userData?.username,
                company_address: state.userData?.address || "N/A",
                customer_name: obj?.name,
                customer_address: obj?.address.length > 0 ? obj?.address.split(" ")[0] : "N/A",
                bill_month: getDate(),
                customer_phone: obj.phonenumber,
                invoice_number: generateInvoiceNumber(index),
                account_number: obj?.account,
                meter_number: obj.username,
                due_date: getDatebyCategory("Invoice Defaulting"),
                previous_bill_date: getPeriodEnding(),
                defaulted_balance: getDefaultedBalance(obj?.account).toFixed(2),
                meter_rent: obj?.other_details?.meter_rent || 0,
                tables_data: [[obj.previous_reading !== null ? obj.previous_reading.toFixed(2) : "0", obj.last_reading !== null ? obj?.last_reading.toFixed(2) : "0", ((obj?.last_reading || 0) - (obj?.previous_reading || 0)).toFixed(2)]],
                water_blocks: table2,
                total_water_charges: cost.toFixed(2),
                has_standing_charges: standing_charges > 0,
                standing_charges: standing_charges,
                sub_total_amount: total_amount_due.toFixed(2),
                total: total_amount_due.toFixed(2),
                vat_percentage: "0",
                vat_amount: "0.00",
                total_charges: total_amount_due.toFixed(2),
                reading_date: startsIn.getDate().toString(),
                last_reading_month: getLastReadingMonth(),
                current_reading_month: getCurrentReadingMonth(),
                current_year: startsIn.getFullYear().toString(),
                has_sewer_charges: sewer_tarrifs.length > 0 && obj.sewer,
                sewer_blocks: table3,
                total_sewer_charges: obj.sewer && sewer_tarrifs.length > 0 ? sewer_cost.toFixed(2) : 0,
                vat: "0",
                output_type: "arraybuffer",
                filename: obj.name + "-" + generateInvoiceNumber(index),
                return_jspdfdoc_obj: true,
                account_status: obj.disconnected ? "Disconnected" : "Connected"
                },
                customer: obj
            }
            

            return {pdf: invoice_generated.arrayBuffer, invoice: invoiceData};

        }
    }

    const billByEmail = async (id, index) => {
        let idx = id//customers.findIndex((obj => obj._id === id));

        if(idx !== -1){
            let obj = customers[idx];

            let cost = tarrifs.length > 0 ? createCost((obj.last_reading - obj.previous_reading), obj.tarrif) : 0;

            const sewer_cost = obj.sewer && sewer_tarrifs.length > 0 ? createSwrCost((obj.last_reading - obj.previous_reading), obj.tarrif) : 0;

            const table2 = tarrifs.length > 0 ? createWaterBlocks((obj.last_reading - obj.previous_reading), obj.tarrif) : [["0.00", "0.00", "0.00"]];

            const table3 = obj.sewer && sewer_tarrifs.length > 0 ? createSewerBlocks((obj.last_reading - obj.previous_reading), obj.tarrif) : [];

            const total_amount_due = cost + sewer_cost + standing_charges + (obj?.other_details?.meter_rent || 0) + (obj?.other_details?.standing_charges || 0);

            let invoice_number = generateInvoiceNumber(index);

            let pdfObject = generateInvoice({
                water_service_name: state.userData.waterServiceName,
                company_phone: state.userData?.phonenumber,
                company_email: state.userData?.username,
                company_address: state.userData?.address || "N/A",
                customer_name: obj?.name,
                customer_address: obj?.address || "N/A",
                bill_month: getDate(),
                customer_phone: obj.phonenumber,
                invoice_number: generateInvoiceNumber(index),
                account_number: obj?.account || "A432",
                meter_number: obj.username,
                due_date: getDatebyCategory("Invoice Defaulting"),
                previous_bill_date: getPeriodEnding(),
                defaulted_balance: getDefaultedBalance(obj?.account).toFixed(2),
                tables_data: [[obj.previous_reading !== null ? obj.previous_reading.toFixed(2) : "0", obj.last_reading !== null ? obj?.last_reading.toFixed(2) : "0", ((obj?.last_reading || 0) - (obj?.previous_reading || 0)).toFixed(2)]],
                water_blocks: table2,
                total_water_charges: cost.toFixed(2),
                has_standing_charges: standing_charges > 0,
                standing_charges: standing_charges,
                sub_total_amount: total_amount_due.toFixed(2),
                total: total_amount_due.toFixed(2),
                vat_percentage: "0",
                vat_amount: "0.00",
                total_charges: total_amount_due.toFixed(2),
                reading_date: "26",
                last_reading_month: getLastReadingMonth(),
                current_reading_month: getCurrentReadingMonth(),
                current_year: (new Date().getFullYear()).toString(),
                has_sewer_charges: sewer_tarrifs.length > 0 && obj.sewer,
                sewer_blocks: table3,
                total_sewer_charges: obj.sewer && sewer_tarrifs.length > 0 ? sewer_cost.toFixed(2) : 0,
                vat: "0",
                output_type: "arraybuffer",
                filename: obj.name + "-" + generateInvoiceNumber(index),
                return_jspdfdoc_obj: true,
                account_status: obj.disconnected ? "Disconnected" : "Connected"
            });

            const email_attachment = btoa(String.fromCharCode.apply(null, new Uint8Array(pdfObject.arrayBuffer)));

            axios.post("/bills/bot-batch-bills", {
                img: "https://res.cloudinary.com/e-mita/image/upload/v1682091016/emita-logo-orig_k94ncx.png",
                customer_mail: obj.email,
                company_name: state.userData.waterServiceName,
                company_email: state.userData.username,
                meter_number: obj.username,
                name: obj.name,
                bill_number: invoice_number,
                bill_month: getDate(),
                due_date: getDatebyCategory("Invoice Defaulting"),
                last_reading_month: getLastReadingMonth(),
                current_reading_month: getCurrentReadingMonth(),
                reading_date: dates === null ? "24" : dates?.starts.toString(),
                current_year: (new Date().getFullYear()).toString(),
                account_number: obj.account,
                last_reading: obj.previous_reading,
                current_reading: obj.last_reading,
                total_consumption: (obj.last_reading - obj.previous_reading),
                tarrif: obj.tarrif,
                vat: "0",
                current_charges: cost,
                sewer_charges: sewer_cost,
                past_amount: 0,
                total: total_amount_due,
                arrayBuffer: email_attachment
            }).then(function(res){
                if(res.status === 200){
                    
                }
            }).catch(function(error){
                console.log(error);
            })

        } else {

        }
    }

    const billBySMS = async (id) => {
        let idx = customers.findIndex((obj => obj._id === id));

        if(idx !== -1){
            const obj = customers[idx];

            const tala_b = obj?.account === "TALABOYSPRIMARY";

            const kusu = obj?.account === "PATRICKKUSU"

            const units_consumed_factor = tala_b ? 20 : kusu ? 30 : 0;

            const cost = createCost(((obj.last_reading - obj.previous_reading) - units_consumed_factor), obj.tarrif);

            const sewer_cost = createSwrCost(((obj.last_reading - obj.previous_reading) - units_consumed_factor), obj.tarrif);

            const total_amount_due = cost + sewer_cost + standing_charges + (obj?.other_details?.meter_rent || 0) + (obj?.other_details?.standing_charges || 0);

            let date = new Date();

            date.setMonth(date.getMonth() - 1);

            let invoice_number = generateInvoiceNumber(idx + 1);

            axios.post("/sms//mobitech-sms", {
                to: "254741582811",
                sms: `Dear ${obj.name},you have a pending water bill of KSH.${total_amount_due} for the period ending ${getPeriodEnding()}.From ${state.userData.waterServiceName}`
            }).then(function(res){
                if(res.status === 200){
                    //
                }
            }).catch(function(error){
                console.log(error);
            })             

        } else {

        }
    }

    const  paginate = (a, pageIndex, pageSize) => {
        pageIndex = pageIndex - 1;
        var endIndex = Math.min((pageIndex + 1) * pageSize, a.length);
        return a.slice(Math.max(endIndex - pageSize, 0), endIndex);   
    }

    const toggleRow = (id) =>
        setSelection((current) =>
            current.includes(id) ? current.filter((item) => item !== id) : [...current, id]
    );

    const toggleAll = () =>
      setSelection((current) => (current.length === customers.length ? [] : customers.map((item) => item._id)));

    
    const draftFiltering = (account) => {
        if(toggle){
            let result = false;
            for(let i=0; i<meters_read.length;i++){
                let arr = meters_read[i].read_accounts;

                if(arr.includes(account)){
                    result = true;
                }
            }

            return result;
        } else {
            return true;
        }
    }

    const FormatBadge = ({item}) => {
        const real_time_defaulted = getDefaultedBalance(item.invoices.account_number); 

        const invoiced = parseFloat(item.invoices.total) + parseFloat(item.invoices.defaulted_balance);

        const color = (real_time_defaulted === invoiced && real_time_defaulted > 0) ? "red" : (real_time_defaulted < invoiced && real_time_defaulted > 0) ? "yellow"  : real_time_defaulted === 0 ? "green" : real_time_defaulted < 0 ? "blue" : "Loading";

        const text = (real_time_defaulted === invoiced && real_time_defaulted > 0) ? "Pending" : (real_time_defaulted < invoiced && real_time_defaulted > 0) ? "Partial"  : real_time_defaulted === 0  ? "Complete" : real_time_defaulted < 0 ? "Overpaid" : "Loading";

        return (
            <Badge size="xs" style={{textTransform: "none"}} color={color}>{text}</Badge>
        )
    }

    const saveInvoice = async (pdf) => {
        pdf.output_type = "save"
        generateInvoice(pdf);
    }

    const pushed_bills = bills_pushed_arr.filter((item) => {
        if(item.invoices?.customer_name?.toLowerCase().includes(name) || item.invoices?.account_number?.toLowerCase().includes(name) || item.invoices?.dma?.toLowerCase().includes(name) || item.mode !== "disconnection"){
            return item
        }
    }).map((item, index) => {
        return (
            <tr key={`bill-pushed-${index}`}>
                <td style={{alignItems: "center"}}>
                    <Avatar color="blue" radius={40} size={40} >{item.invoices.customer_name ? item.invoices.customer_name.split("")[0].toUpperCase() : "U"}</Avatar>
                </td>
                <td style={{alignItems: "center", textAlign: "center"}}>
                <Anchor size="xs" target="_blank" component={"a"} href={`/app/customers/${encodeURIComponent(item.invoices.meter_number)}`} >{item.invoices.customer_name.split(" ")[0]}</Anchor>
                </td>
                <td style={{alignItems: "center", textAlign: "center"}}>
                    {item.invoices.account_number}
                </td>
                <td style={{alignItems: "center", textAlign: "center"}}>
                {item.invoices.invoice_number}
                </td>
                <td style={{alignItems: "center", textAlign: "center"}}>
                {item.invoices.total}
                </td>
                <td style={{alignItems: "center", textAlign: "center"}}>
                    {item.invoices.defaulted_balance}
                </td>
                <td style={{alignItems: "center", textAlign: "center"}}>
                    {parseFloat(item.invoices.total) + parseFloat(item.invoices.defaulted_balance)}
                </td>
                <td style={{alignItems: "center", textAlign: "center"}}>
                    <FormatBadge item={item} />
                </td>
                <td>
                {new Date(item.createdAt).toLocaleDateString()}
                </td>
                <td style={{alignItems: "center", textAlign: "center"}}>
                {item.invoices.due_date}
                </td>
                <td style={{alignItems: "center"}}>
                    <Button onClick={() => {saveInvoice(item.invoices)}} variant="light" size="sm">Download Copy</Button>
                </td>
            </tr>
        )
    })

    const rows = paginate(sortedData, page, name !== "" ? sortedData.length : size).filter((item, index) => {
        let it;
        if(tarrif === "all"){
            if(item.name.toLowerCase().includes(name) || item.dma.toLowerCase().includes(name) || item.account.toLowerCase().includes(name)){
                it = item
            }
        } else {
            if(item.tarrif === tarrif && (item.name.toLowerCase().includes(name) || item.dma.toLowerCase().includes(name) || item.account.toLowerCase().includes(name))){
                it = item;
            }
        }

        if(draftFiltering(it?.account)){
            return it;
        }
    }).map((item, index) => {
        const selected = selection.includes(item._id);
        return (
            <tr key={`bill-unpushed-row-${index}`} className={cx({ [classes.rowSelected] : selected})}>
                <td>
                    <Checkbox checked={selection.includes(item._id)}
                    onChange={() => toggleRow(item._id)}
                    transitionDuration={0}
                    />
                </td>
                <td>
                    <Avatar color="blue" radius={40} size={40}>{item.name ? item.name.split("")[0].toUpperCase() : "~"}</Avatar>
                </td>
                <td>
                    <Anchor target="_blank" component={"a"} href={`/app/customers/${encodeURIComponent(item.username)}`}>
                    {textClip(item.name, 35)}
                    </Anchor>
                </td>
                <td>
                    {item.account}
                </td>
                <td>
                    {item.username}
                </td>
                <td>
                    {item.dma}
                </td>
                <td>
                <Badge style={{textTransform: 'none'}} color={!item.disconnected ? "green" : "yellow"}>{item.disconnected ? "Disconnected" : "Connected"}</Badge>
                </td>
                <td>
                {item.previous_reading !== null ? item.previous_reading.toFixed(0) : "0"}
                </td>
                <td>
                {item.last_reading !== null ? item.last_reading.toFixed(0) : "0"}
                </td>
                <td>
                    {item.last_reading - item.previous_reading}
                </td>
                <td>
                {getDefaultedBalance(item.account)}
                </td>
                <td>
                    {createCost((item.last_reading - item.previous_reading), item.tarrif).toFixed(2)}
                </td>
                {sewer_tarrifs.length > 0 ? (
                    <td>{createSwrCost((item.last_reading - item.previous_reading), item.tarrif).toFixed(2)}</td>
                ) : null}
                <td>
                {(getDefaultedBalance(item.account)+ standing_charges + createCost((item.last_reading - item.previous_reading), item.tarrif) + createSwrCost((item.last_reading - item.previous_reading), item.tarrif))}
                </td>

            </tr>
        )
    });

    const draft_rows = sortedData.filter((item) => {
        if(draftFiltering(item.account) && !item.disconnected){
            return item;
        } 
    }).map((item, index) => {
        const selected = selection.includes(item._id);
        var x = getDefaultedBalance(item.account);
        var y = createCost((item.last_reading - item.previous_reading), item.tarrif)
        var z = createSwrCost((item.last_reading - item.previous_reading), item.tarrif);

        return (
            <tr key={`draft-bill-row-${index}`} className={cx({ [classes.rowSelected] : selected})}>
                <td>
                    <Anchor component={"a"} target="_blank" href={`/app/customers/${encodeURIComponent(item.username)}`}>
                    {textClip(item.name, 35)}
                    </Anchor>
                </td>
                <td>
                    {item.account}
                </td>
                <td>
                    {item.dma}
                </td>
                <td>
                {item.previous_reading !== null ? item.previous_reading.toFixed(0) : "0"}
                </td>
                <td>
                {item.last_reading !== null ? item.last_reading.toFixed(0) : "0"}
                </td>
                <td>
                    {item.last_reading - item.previous_reading}
                </td>
                <td>
                    {x}
                </td>
                <td>
                    {y}
                </td>
                {sewer_tarrifs.length > 0 ? (
                    <td>{z}</td>
                ) : null}
                <td>
                {(x+standing_charges + y + z)}
                </td>

            </tr>
        )
    })

    const exportAll = () => {
        let arr = [];

        for(let i=0; i<sortedData.length; i++){
            let item = sortedData[i];
            let amountBilled = ((item?.previous_balance || 0)+standing_charges + createCost((item.last_reading - item.previous_reading), item.tarrif) + createSwrCost((item.last_reading - item.previous_reading), item.tarrif)) > 0 ? ((item?.previous_balance || 0)+standing_charges + createCost((item.last_reading - item.previous_reading), item.tarrif) + createSwrCost((item.last_reading - item.previous_reading), item.tarrif)).toFixed(2) : "0.00"

            arr.push({account: item.account, AprilReading: item?.previous_reading, MayReading: item.last_reading, AprilDefaultedBalance: item?.previous_balance || 0, TotalMayBalance: amountBilled});
        }


        downloadCSV(arr, `${state.userData.waterServiceName} ${getDate()} Bills`);
    }

    const theme = useMantineTheme();

    const { height } = useViewportSize();

    function sortData(data,payload) {
        const { sortBy } = payload;
        
        return (
            [...data].sort((a, b) => {
            if (payload.reversed) {
                return b[sortBy].localeCompare(a[sortBy]);
            }
        
            return a[sortBy].localeCompare(b[sortBy]);
            })
        );
    }

    const handleUnreadCheckbox = (account) => {
        if(unread_checkboxes.includes(account)){
            let idx = unread_checkboxes.indexOf(account);

            unread_checkboxes.splice(idx, 1);

            setUnreadCheckboxes([...unread_checkboxes]);
        } else {
            setUnreadCheckboxes(prevArr => ([...prevArr, account]))
        }
    }

    const handleReadCheckbox = (account) => {
        if(read_checkboxes.includes(account)){
            let idx = read_checkboxes.indexOf(account);

            read_checkboxes.splice(idx, 1);

            setReadCheckboxes([...read_checkboxes]);
        } else {
            setReadCheckboxes(prevArr => ([...prevArr, account]))
        }
    }

    const exportReadValues = () => {
        if(read_checkboxes.length === 0){
            toast.error("No accounts have been selected for export.", {
                position: "bottom-right",
                duration: 5000
            });
            return false;
        } 

        downloadText(read_checkboxes, "Read_accounts");

        setReadCheckboxes([]);
    }

    const exportUnreadValues = () => {
        if(unread_checkboxes.length === 0){
            toast.error("No accounts have been selected for export.", {
                position: "bottom-right",
                duration: 5000
            });
            return false;
        }

        downloadText(unread_checkboxes, "Unread_accounts");

        setUnreadCheckboxes([]);

    } 

    const getNumberOfMetersRead = () => {
        let num = 0; 
        for(let i=0; i<meters_read.length; i++){
            num += meters_read[i].read_accounts.length;
        }

        return num;
    }

    const populateReadCheckboxes = () => {
        let readArray = [];

        for(let i=0; i<meters_read.length; i++){
            let arr = meters_read[i];

            for(let k=0; k<arr.read_accounts.length; k++){
                readArray.push(arr.read_accounts[k])
            }
        }

        setReadCheckboxes(readArray);
    }

    const explodeReadCheckboxes = () => {
        setReadCheckboxes([]);
    }

    const getNumberOfMetersUnread = () => {
        let num = 0; 
        for(let i=0; i<unread_meters.length; i++){
            num += unread_meters[i].unread_accounts.length;
    }

        return num;
    }

    const populateUnreadCheckboxes = () => {
        let unread_array = [];

        for(let i=0; i<unread_meters.length; i++){
            let arr = unread_meters[i];

            for(let k=0; k<arr.unread_accounts.length; k++){
                unread_array.push(arr.unread_accounts[k]);
            }
        }

        setUnreadCheckboxes(unread_array);
    }

    const explodeUnreadCheckboxes = () => {
        setUnreadCheckboxes([]);
    }

    return(
        <>
        <Helmet>
            <meta charSet='utf-8' />
            <title>{state.userData.waterServiceName} - Payments Management Console</title>
        </Helmet>

        <Alert mt={-20} ml={-20} mr={-15} icon={<IconInfoCircle />}>
            Emita end-to-end pipeline is sequential. That means that meter readings have to be taken and bills delivered to have a list of payments. Although some payments may be received in-sequentially, they will not be displayed.
        </Alert>

        <Group mt={20} mb={20} position="apart">
            <Heading level={6} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Bill Optimization Tools</Heading>
      </Group>

       {!is_review_window && ready && payments_ready && invoices_ready ? (
       <Paper p="md">
            {show_notification ? (
                            <Notification styles={{title: {color: "white"}, description: {color: "white", paddingTop: 10}, icon: {color: "white", fill: "white"}, closeButton: {color: "white", ":hover": {backgroundColor: "transparent"}}}} sx={() => ({color: 'white'})} color="#ffffff" bg={"blue"} mb={20} onClose={() => {
                                setShowNotification(false);
                                setBulkAction("")
                            }} title="Bulk Action Alert" icon={<InfoCircle />}>
                                Bulk action - <strong>{bulk_action}</strong> - has finished successfully.
                            </Notification>
            ) : null}

            {reminder_status ? (
                <Notification loading={true} styles={{title: {color: "white"}, description: {color: "white", paddingTop: 10}, icon: {color: "white", fill: "white"}, closeButton: {color: "white", ":hover": {backgroundColor: "transparent"}}}} sx={() => ({color: 'white'})} color="#ffffff" bg={"blue"} mb={20} onClose={() => {

                }} title="Action in progress" icon={<InfoCircle />}>
                    Delivering reminders to customers. Please wait until all the reminders have been sent.
                </Notification>
            ) : null}

            {sms_status ? (
                <Notification loading={true} styles={{title: {color: "white"}, description: {color: "white", paddingTop: 10}, icon: {color: "white", fill: "white"}, closeButton: {color: "white", ":hover": {backgroundColor: "transparent"}}}} sx={() => ({color: 'white'})} color="#ffffff" bg={"blue"} mb={20} onClose={() => {

                }} title="Action in progress" icon={<InfoCircle />}>
                    Delivering sms invoices to customers. Please wait until all the sms have been sent.
                </Notification>
            ) : null}


            {pdf_status ? (
                <Notification loading={true} styles={{title: {color: "white"}, description: {color: "white", paddingTop: 10}, icon: {color: "white", fill: "white"}, closeButton: {color: "white", ":hover": {backgroundColor: "transparent"}}}} sx={() => ({color: 'white'})} color="#ffffff" bg={"blue"} mb={20} onClose={() => {

                }} title="Action in progress" icon={<InfoCircle />}>
                    Generating, calculating and saving customer invoices. Please wait until all the operations have terminated.
                </Notification>
            ) : null}

            <Group position="apart" spacing={"xs"} noWrap>
                <Group>
                <Text size="xs">Total Bills: <strong><span style={{color: "teal"}}>{bills_pushed_count}</span></strong></Text>
                <Text size="xs">Currently Showing: <strong>{bills_pushed_arr.length}</strong></Text>
                <Text size="xs">Pending: <strong><span style={{color: "red"}}>{pending}</span></strong></Text>
                <Text size="xs">Partial: <strong><span style={{color: "yellow"}}>{partial}</span></strong></Text>
                <Text size="xs">Complete: <strong><span style={{color: "green"}}>{complete}</span></strong></Text>
                <Text size="xs">Overpaid: <strong><span style={{color: "blue"}}>{overpaid}</span></strong></Text>
                <Button onClick={() => {exportAll()}} variant="subtle" size="xs" leftIcon={<FileExport size={13} />}>Export All</Button>
                </Group>
                <Group position="right">
                {customers.length > 0 && payments_ready && invoices_ready && (dates?.starts < new Date().getDate()) && (new Date(endsIn).getMonth() === new Date().getMonth()) && (new Date(endsIn).getFullYear() === new Date().getFullYear()) ? (
                    <Button onClick={() => {overrideSystemInvoicing()}}>Push Bills </Button>
                ) : !billsPushed && ready ? (
                    <Menu width={200} trigger="hover">
                        <Menu.Target>
                        <Button color="orange">Push Bills</Button>
                        </Menu.Target>
                        <Menu.Dropdown>
                            {/*<Menu.Item onClick={() => {pushInvoices(false)}}>Push Bills By Batch Download</Menu.Item> */}
                            <Menu.Item onClick={() => {pushInvoices(true)}}>Push Bills By Batch SMS</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                ) : null}
                {is_review_window && ready ? (
                    <Button onClick={() => {setToggle(false)}} variant="default">Toggle Review Cycle</Button>
                ) : null}

                {!is_review_window && billsPushed && ready ? (
                    <Button disabled={send_reminder} variant="default" onClick={() => {sendReminders()}}>Send Batch Reminders</Button>
                ) : null}
                <Select value={size.toString()} onChange={(val) => {setSize(parseInt(val))}} data={[
                    {label: "25", value: "25"},
                    {label: "50", value: "50"},
                    {label: "100", value: "100"},
                    {label: "250", value: "250"},
                    {label: "500", value: "500"},
                    {label: "1000", value: "1000"},
                    {label: "5000", value: "5000"}
                ]} />
                </Group>
            </Group>
            <Space h="xs" />
            <Divider />
            <Space h="xs" />
            <SearchField hasSearchButton={false} hasSearchIcon inputStyles={{color: theme.colorScheme === "dark" ? theme.colors.gray[6] : theme.colors.dark[9]}} width='100%' placeholder="Find customers by name" value={name} onChange={(e) => {setName(e.target.value.toLowerCase())}} onClear={() => {setName("")}} />
            <Space h="xs" />
                    {billsPushed && ready ? (
                                    <div style={{overflowX: 'auto'}} >
                                    <Table fontSize="xs"  style={{borderBottom: '1px solid #E9ECEF'}}>
                                        <thead>
                                            <tr>
                                                <th>
                                                    #
                                                </th>
                                                <th>
                                                    Name
                                                </th>
                                                <th>
                                                    Account
                                                </th>
                                                <th>
                                                    Invoice#
                                                </th>
                                                <th>
                                                    Consumption Cost
                                                </th>
                                                <th>
                                                    Previous Balance
                                                </th>
                                                <th>
                                                    Total Due
                                                </th>
                                                <th>
                                                    Status
                                                </th>
                                                <th>
                                                    Invoice Date
                                                </th>
                                                <th>
                                                    Due Date
                                                </th>
                                                <th>
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bills_pushed_arr.length === 0 ? (
                                                <tr>
                                                    <td colSpan={8}>
                                                        <Center mt={"10%"} mb={"10%"}>
                                                            No data was found
                                                        </Center>
                                                    </td>
                                                </tr>
                                            ) : pushed_bills}
                                        </tbody>
                                    </Table>
                            </div>
                    ) : (
                        <div style={{overflowX: 'auto'}}>
                        {ready && payments_ready && invoices_ready ? (
                        <Table fontSize="xs" style={{borderBottom: '1px solid #E9ECEF'}}>
                        <thead className={cx(classes.header, { [classes.scrolled]: scrolled })} >
                            <tr>
                                <th style={{ width: 40}}>
                                <Checkbox
                                onChange={toggleAll}
                                checked={selection.length === customers.length}
                                indeterminate={selection.length > 0 && selection.length !== customers.length}
                                transitionDuration={0}
                                />
                                </th>
                                <th>
                                    #
                                </th>
                                <th>Name</th>
                                <th >
                                    Account
                                </th>
                                <th
                                >
                                Meter
                                </th>
                                <th>
                                    DMA
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    Last(M³)
                                </th>
                                <th>
                                    Current(M³)
                                </th>
                                <th>
                                    Units(M³)
                                </th>
                                <th>
                                    Previous Balance
                                </th>
                                <th>
                                    Water Cost
                                </th>
                                {sewer_tarrifs.length > 0 ? (
                                <th>
                                Sewer Cost
                            </th>
                                ) : null}
                                <th>
                                    Total Cost
                                </th>
                               {/* <th>
                                    Action
                                </th>*/}
                            </tr>
                        </thead>
                        <tbody>
                            {customers.length === 0 ? (
                                <tr>
                                    <td colSpan={10} >
                                        <Center>
                                            <Text>No data available</Text>
                                        </Center>
                                    </td>
                                </tr>
                            )  : rows}
                        </tbody>
                    </Table>
                        ) : (
                            <Center>
                            <div
              className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
              role="status">
              <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >
                    Loading...
                </span>
            </div>
                        </Center>
                        )}
                </div>
                    )}
            <Group mt={20} position="right">
                <Pagination size="sm" page={page} onChange={setPage} total={pagination} siblings={0} spacing="xs" withControls radius="xs" />
            </Group>
        </Paper> 
        ) : is_review_window && ready ? (
        <>
            <Group mt={20} mb={20} position="apart">
            <Heading level={6} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Review Cycle</Heading>
            <Group>
                <Button size="xs" radius={28} onClick={() => {setImageAnalysis(!image_analysis)}}>{image_analysis ? "Close Image Validation" : "Image Validation"}</Button>
             <Button size="xs" radius={28} onClick={() => {
                setImageAnalysis(false);
                setToggle(!toggle)
                }}>{toggle ? "Draft Readings" : "Draft Bills"}</Button>
            </Group>
            </Group>
             <Text mb={20}>Your are currently on a meter reading cycle. The cycle started on {startsIn ? startsIn.toLocaleDateString() : "[start]"} and ends in {endsIn ? endsIn.toLocaleDateString() : "[end]"}. You can review and manage your meter reading cycle configurations <Anchor component={Link} to="/app/configure/dates">here</Anchor></Text>
             {!toggle ? <Divider ml={-10} mr={-10} /> : null}
            {!toggle && !image_analysis ?
             <Grid>
                <Grid.Col mt={8} sm={12} lg={7} style={{height: (height - HEADER_HEIGHT - 220), marginBottom: 40, borderRight: theme.colorScheme === "dark" ? `2px solid ${theme.colors.dark[6]}` : `2px solid ${theme.colors.gray[4]}`}}>
                <SearchField hasSearchButton={false} hasSearchIcon inputStyles={{color: theme.colorScheme === "dark" ? theme.colors.gray[6] : theme.colors.dark[9]}} width='100%' placeholder="Find customers by account" value={name} onChange={(e) => {setName(e.target.value.toLowerCase())}} onClear={() => {setName("")}}/>   
                <Group mt={10} position="apart" style={{overflowX: "auto"}}>
                    <Button variant="subtle" onClick={() => {setCurrentView("all")}} size="xs">All: <strong><span style={{color: "green"}}>{total_read_meters}</span></strong></Button>
                <Button onClick={() => {exportReadValues()}} variant="subtle" size="xs" leftIcon={<FileExport size={13} />}>Export Selected</Button>
                <Button variant="subtle" onClick={() => {setCurrentView("critical")}} size="xs">
                <span className="relative flex h-1 w-2">
                <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500"
                ></span>
                </span> Critical Issue <strong>{`-(${critical_issues.length})`}</strong>:                
                </Button>

                <Button variant="subtle" onClick={() => {setCurrentView("average")}} size="xs">
                <span className="relative flex h-1 w-2">
                <span className="relative inline-flex h-2 w-2 rounded-full bg-yellow-500"
                ></span>
                </span> Average Issue <strong>{`-(${average_issues.length})`}</strong>
                </Button>
                <Button variant="subtle" size="xs"><span className="relative flex h-1 w-2">
                <span className="relative inline-flex h-2 w-2 rounded-full bg-green-500"
                ></span>
                </span> OK</Button>
               </Group>
                <Divider ml={-10} mr={-10} mt={10} mb={10} />  
                    <div style={{overflowY: "auto", height:(height - HEADER_HEIGHT - 330)}}>
                            {meters_read.length === 0 ? (
                                        <>
                                        <Group position="center" mt="30%">
                                        <FontAwesomeIcon icon={tasks_loading ? faSpinner : faList} spin={tasks_loading} size="2xl" />
                                        </Group>
                                
                                        <Text align="center" mt={30}>{tasks_loading ? "Fetching meter data..." : "No data was found."}</Text>
                                        </>
                            ) : (
                                <Table fontSize="xs">
                                <thead>
                                    <tr>
                                        <th>
                                            <Checkbox size="xs" checked={read_checkboxes.length === getNumberOfMetersRead()} onChange={(e) => {e.currentTarget.checked ? populateReadCheckboxes() : explodeReadCheckboxes()}} />
                                        </th>
                                        <th>Account</th>
                                        <th>Mean</th>
                                        <th>Units</th>
                                        <th>Previous</th>
                                        <th>Current</th>
                                        <th>Status</th>
                                        <th>Zone</th>
                                        <th>Comment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {current_view === "all" ? (
                                        meters_read.map((item) => {
                                            return (
                                                item.read_accounts.filter((item) => {
                                                    if(name !== ""){
                                                        if(item.toLowerCase().includes(name.toLowerCase())){
                                                            return item
                                                        }
                                                    } else {
                                                        return item;
                                                    }
                                                }).map((item, index) => {
                                                    return (
                                                        <tr key={`read-account-${index}`}>
                                                            <td>
                                                            <Checkbox checked={read_checkboxes.includes(item)} onChange={() => {handleReadCheckbox(item)}} size='xs' transitionDuration={0} />
                                                            </td>
                                                            <td><Anchor target="_blank" href={"/app/customers/"+encodeURIComponent(getDataCategory(item, "meter"))+"?tab=profile&tk="+state.userData._id}>{item}</Anchor></td>
                                                            <td>
                                                                {parseFloat(getAverage(item)).toFixed()}
                                                            </td>
                                                            <td>{(parseInt(getDataCategory(item, "current")) - parseFloat(getDataCategory(item, "previous")))}</td>
                                                            <td>{getDataCategory(item, "previous")}</td>
                                                            <td>
                                                            <Anchor className="mr-1" href={getDataCategory(item, "image")} rel="noreferrer" target="_blank">{getDataCategory(item, "current")}</Anchor>
                                                            {(parseFloat(getDataCategory(item, "current")) - parseFloat(getDataCategory(item, "previous"))) < 0  ? (
                                                            <span className="relative flex h-1 w-2">
                                                            <span
                                                            className="absolute inline-flex h-2 w-2 animate-ping rounded-full bg-red-400 opacity-75"
                                                            ></span>
                                                            <span
                                                            className="relative inline-flex h-2 w-2 rounded-full bg-red-500"
                                                            ></span>
                                                        </span>
                                                            ) : Math.abs((parseInt(getDataCategory(item, "current")) - parseFloat(getDataCategory(item, "previous"))) - getAverage(item)) > 5 ? (
                                                                <span className="relative flex h-1 w-2">
                                                                <span
                                                                className="absolute inline-flex h-2 w-2 animate-ping rounded-full bg-yellow-400 opacity-75"
                                                                ></span>
                                                                <span
                                                                className="relative inline-flex h-2 w-2 rounded-full bg-yellow-500"
                                                                ></span>
                                                            </span>
                                                            ) : (
                                                                <span className="relative flex h-1 w-2">
                                                                <span
                                                                  className="relative inline-flex h-2 w-2 rounded-full bg-green-500"
                                                                ></span>
                                                              </span>
                                                            ) }
                                                             </td>
                                   
                                                            <td><Badge size="xs" style={{textTransform: "none"}} color={getDataCategory(item, "status") === "Connected" ? "green" : "red"}>{getDataCategory(item, "status")}</Badge></td>
                                                            <td>
                                                                {getDataCategory(item, "dma")}
                                                             </td>
                                                            <td>
                                                                {getDataCategory(item, "comment")}
                                                             </td>
                                                        </tr>
                                                    )
                                                })
                                            )
                                        })
                                    ) : current_view === "critical" ? (
                                                critical_issues.filter((item) => {
                                                    if(name !== ""){
                                                        if(item.toLowerCase().includes(name.toLowerCase())){
                                                            return item
                                                        }
                                                    } else {
                                                        return item;
                                                    }
                                                }).map((item, index) => {
                                                    return (
                                                        <tr key={`read-account-${index}`}>
                                                            <td>
                                                            <Checkbox checked={read_checkboxes.includes(item)} onChange={() => {handleReadCheckbox(item)}} size='xs' transitionDuration={0} />
                                                            </td>
                                                            <td><Anchor target="_blank" href={"/app/customers/"+encodeURIComponent(getDataCategory(item, "meter"))+"?tab=profile&tk="+state.userData._id}>{item}</Anchor></td>
                                                            <td>
                                                                {parseFloat(getAverage(item)).toFixed()}
                                                            </td>
                                                            <td>{(parseInt(getDataCategory(item, "current")) - parseFloat(getDataCategory(item, "previous")))}</td>
                                                            <td>{getDataCategory(item, "previous")}</td>
                                                            <td>
                                                            <Anchor className="mr-1" href={getDataCategory(item, "image")} rel="noreferrer" target="_blank">{getDataCategory(item, "current")}</Anchor>
                                                            <span className="relative flex h-1 w-2">
                                                            <span
                                                            className="absolute inline-flex h-2 w-2 animate-ping rounded-full bg-red-400 opacity-75"
                                                            ></span>
                                                            <span
                                                            className="relative inline-flex h-2 w-2 rounded-full bg-red-500"
                                                            ></span>
                                                        </span>
                                                             </td>

                                                            <td><Badge size="xs" style={{textTransform: "none"}} color={getDataCategory(item, "status") === "Connected" ? "green" : "red"}>{getDataCategory(item, "status")}</Badge></td>
                                                            <td>
                                                                {getDataCategory(item, "dma")}
                                                             </td>
                                                            <td>
                                                                {getDataCategory(item, "comment")}
                                                             </td>
                                                        </tr>
                                                    )
                                                })
                                    ) : current_view === "ok" ? (
                                        ok.filter((item) => {
                                            if(name !== ""){
                                                if(item.toLowerCase().includes(name.toLowerCase())){
                                                    return item
                                                }
                                            } else {
                                                return item;
                                            }
                                        }).map((item, index) => {
                                            return (
                                                <tr key={`read-account-${index}`}>
                                                    <td>
                                                    <Checkbox checked={read_checkboxes.includes(item)} onChange={() => {handleReadCheckbox(item)}} size='xs' transitionDuration={0} />
                                                    </td>
                                                    <td><Anchor target="_blank" href={"/app/customers/"+encodeURIComponent(getDataCategory(item, "meter"))+"?tab=profile&tk="+state.userData._id}>{item}</Anchor></td>
                                                    <td>
                                                        {parseFloat(getAverage(item)).toFixed()}
                                                    </td>
                                                    <td>{(parseInt(getDataCategory(item, "current")) - parseFloat(getDataCategory(item, "previous")))}</td>
                                                    <td>{getDataCategory(item, "previous")}</td>
                                                    <td>
                                                    <Anchor className="mr-1" href={getDataCategory(item, "image")} rel="noreferrer" target="_blank">{getDataCategory(item, "current")}</Anchor>
                                                    <span className="relative flex h-1 w-2">
                                                                <span
                                                                  className="relative inline-flex h-2 w-2 rounded-full bg-green-500"
                                                                ></span>
                                                              </span>
                                                     </td>

                                                    <td><Badge size="xs" style={{textTransform: "none"}} color={getDataCategory(item, "status") === "Connected" ? "green" : "red"}>{getDataCategory(item, "status")}</Badge></td>
                                                    <td>
                                                                {getDataCategory(item, "dma")}
                                                             </td>
                                                    <td>
                                                                {getDataCategory(item, "comment")}
                                                             </td>
                                                    </tr>
                                            )
                                        })
                                    ) : (
                                        average_issues.filter((item) => {
                                            if(name !== ""){
                                                if(item.toLowerCase().includes(name.toLowerCase())){
                                                    return item
                                                }
                                            } else {
                                                return item;
                                            }
                                        }).map((item, index) => {
                                            return (
                                                <tr key={`read-account-${index}`}>
                                                    <td>
                                                    <Checkbox checked={read_checkboxes.includes(item)} onChange={() => {handleReadCheckbox(item)}} size='xs' transitionDuration={0} />
                                                    </td>
                                                    <td><Anchor target="_blank" href={"/app/customers/"+encodeURIComponent(getDataCategory(item, "meter"))+"?tab=profile&tk="+state.userData._id}>{item}</Anchor></td>
                                                    <td>
                                                        {parseFloat(getAverage(item)).toFixed()}
                                                    </td>
                                                    <td>{(parseInt(getDataCategory(item, "current")) - parseFloat(getDataCategory(item, "previous")))}</td>
                                                    <td>{getDataCategory(item, "previous")}</td>
                                                    <td>
                                                    <Anchor className="mr-1" href={getDataCategory(item, "image")} rel="noreferrer" target="_blank">{getDataCategory(item, "current")}</Anchor>
                                                        <span className="relative flex h-1 w-2">
                                                        <span
                                                        className="absolute inline-flex h-2 w-2 animate-ping rounded-full bg-yellow-400 opacity-75"
                                                        ></span>
                                                        <span
                                                        className="relative inline-flex h-2 w-2 rounded-full bg-yellow-500"
                                                        ></span>
                                                    </span>
                                                     </td>
                                                    <td><Badge size="xs" style={{textTransform: "none"}} color={getDataCategory(item, "status") === "Connected" ? "green" : "red"}>{getDataCategory(item, "status")}</Badge></td>
                                                    <td>
                                                                {getDataCategory(item, "dma")}
                                                    </td>
                                                    <td>
                                                                {getDataCategory(item, "comment")}
                                                             </td>
                                                </tr>
                                            )
                                        })
                                    )}
                                </tbody>
                            </Table>
                            )}
                    </div>
                </Grid.Col>

                <Grid.Col sm={12} mt={8} lg={5} style={{height: (height - HEADER_HEIGHT - 220), marginBottom: 40,}}>
                <SearchField hasSearchButton={false} hasSearchIcon inputStyles={{color: theme.colorScheme === "dark" ? theme.colors.gray[6] : theme.colors.dark[9]}} width='100%' placeholder="Find customers by account" value={name2} onChange={(e) => {setName2(e.target.value.toLowerCase())}} onClear={() => {setName2("")}} />   
               <Group mt={10} position="apart" noWrap>
                    <Group>
                    <Text size="xs">Unread: <strong><span style={{color: "red"}}>{total_unread_meters}</span></strong></Text>
                <Button onClick={() => {exportUnreadValues()}} variant="subtle" size="xs" leftIcon={<FileExport size={13} />}>Export Selected</Button>
                    </Group>

               </Group>
                <Divider ml={-10} mr={-10} mt={10} mb={10} /> 

                    <div style={{overflowY: "auto", height:(height - HEADER_HEIGHT - 330)}}>
                            {unread_meters.length === 0 ? (
                                        <>
                                        <Group position="center" mt="30%">
                                        <FontAwesomeIcon icon={tasks_loading ? faSpinner : faList} spin={tasks_loading} size="2xl" />
                                        </Group>
                                
                                        <Text align="center" mt={30}>{tasks_loading ? "Fetching meter data..." : "No data was found."}</Text>
                                        </>
                            ) : (
                                <Table fontSize="xs">
                                <thead>
                                    <tr>
                                    <th>
                                    <Checkbox size="xs" checked={unread_checkboxes.length === getNumberOfMetersUnread()} onChange={(e) => {e.currentTarget.checked ? populateUnreadCheckboxes() : explodeUnreadCheckboxes()}} />
                                        </th>
                                        <th>Name</th>
                                        <th>Account#</th>
                                        <th>Status</th>
                                        <th>Zone</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {unread_meters.map((item, index) => {
                                        return (
                                            item.unread_accounts.filter((item) => {
                                                if(name2 !== ""){
                                                    if(item.toLowerCase().includes(name2.toLowerCase())){
                                                        return item
                                                    }
                                                } else {
                                                    return item;
                                                }
                                            }).map((item, index) => {
                                                return (
                                                    <tr key={`unread-account-${index}`}>
                                                        <td>
                                                        <Checkbox checked={unread_checkboxes.includes(item)} onChange={() => {handleUnreadCheckbox(item)}}  size="xs" transitionDuration={0} />
                                                        </td>
                                                        <td><Anchor target="_blank" href={"/app/customers/"+encodeURIComponent(getDataCategory(item, "meter"))+"?tab=profile&tk="+state.userData._id}>{getDataCategory(item, "name")}</Anchor></td>
                                                        <td>{item}</td>
                                                        <td><Badge size="xs" style={{textTransform: "none"}} color={getDataCategory(item, "status") === "Connected" ? "green" : "red"}>{getDataCategory(item, "status")}</Badge></td>
                                                        <td>
                                                            {getDataCategory(item, "dma")}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )
                                    })}
                                </tbody>
                            </Table>
                            )}
                    </div> 
                </Grid.Col>
             </Grid>
            : toggle && !image_analysis ? (
                <div style={{overflowX: 'auto'}}>
                        {ready ? (
                        <Table fontSize={"xs"} style={{borderBottom: '1px solid #E9ECEF'}}>
                        <thead className={cx(classes.header, { [classes.scrolled]: scrolled })} >
                            <tr>
                                <th>Name</th>
                                <th >
                                    Account
                                </th>
    
                                <th>
                                    DMA
                                </th>
                                <th>
                                    Last(M³)
                                </th>
                                <th>
                                    Current(M³)
                                </th>
                                <th>
                                    Units(M³)
                                </th>
                                <th>
                                    Balance
                                </th>
                                <th>
                                    Water Cost
                                </th>
                                {sewer_tarrifs.length > 0 ? (
                                <th>
                                Sewer Cost
                            </th>
                                ) : null}
                                <th>
                                    Total Cost
                                </th>
                               {/* <th>
                                    Action
                                </th>*/}
                            </tr>
                        </thead>
                        <tbody>
                            {customers.length === 0 ? (
                                <tr>
                                    <td colSpan={10} >
                                        <Center>
                                            <Text>No data available</Text>
                                        </Center>
                                    </td>
                                </tr>
                            )  : draft_rows}
                        </tbody>
                    </Table>
                        ) : (
                            <Center>
                            <div
              className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
              role="status">
              <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >
                    Loading...
                </span>
            </div>
                        </Center>
                        )}
                </div>
            ) : (
                <>
                    {meters_read.map((item) => {
                        return (
                            
                            <SimpleGrid cols={2} breakpoints={[{maxWidth: 980, cols: 1}]}>
                                {item.read_accounts.filter((item) => {
                                    if(getDataCategory(item, "image") !== ""){
                                        return item
                                    }
                                }).slice(0, image_analysis_amount).map((item, idx) => {
                                    return (
                                        <Paper p="md" key={idx}>
                                            <Table mb={20} fontSize={"xs"}>
                                                <thead>
                                                    <tr>
                                                        <th>Account</th>
                                                        <th>Previous</th>
                                                        <th>Current</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                    <td><Anchor target="_blank" href={"/app/customers/"+encodeURIComponent(getDataCategory(item, "meter"))+"?tab=profile&tk="+state.userData._id}>{item}</Anchor></td>
                                                    <td>{getDataCategory(item, "previous")}</td>
                                                    <td>
                                                    <Anchor className="mr-1" href={getDataCategory(item, "image")} rel="noreferrer" target="_blank">{getDataCategory(item, "current")}</Anchor>
                                                            {(parseFloat(getDataCategory(item, "current")) - parseFloat(getDataCategory(item, "previous"))) < 0  ? (
                                                            <span className="relative flex h-1 w-2">
                                                            <span
                                                            className="absolute inline-flex h-2 w-2 animate-ping rounded-full bg-red-400 opacity-75"
                                                            ></span>
                                                            <span
                                                            className="relative inline-flex h-2 w-2 rounded-full bg-red-500"
                                                            ></span>
                                                        </span>
                                                            ) : Math.abs((parseInt(getDataCategory(item, "current")) - parseFloat(getDataCategory(item, "previous"))) - getAverage(item)) > 5 ? (
                                                                <span className="relative flex h-1 w-2">
                                                                <span
                                                                className="absolute inline-flex h-2 w-2 animate-ping rounded-full bg-yellow-400 opacity-75"
                                                                ></span>
                                                                <span
                                                                className="relative inline-flex h-2 w-2 rounded-full bg-yellow-500"
                                                                ></span>
                                                            </span>
                                                            ) : (
                                                                <span className="relative flex h-1 w-2">
                                                                <span
                                                                  className="relative inline-flex h-2 w-2 rounded-full bg-green-500"
                                                                ></span>
                                                              </span>
                                                            ) }
                                                             </td>
                                                            <td><Badge size="xs" style={{textTransform: "none"}} color={getDataCategory(item, "status") === "Connected" ? "green" : "red"}>{getDataCategory(item, "status")}</Badge></td>

                                                    </tr>
                                                </tbody>
                                            </Table>

                                            <Center>
                                            <img src={getDataCategory(item, "image")} className="h-96 w-auto rounded-full" alt="Image" />
                                            </Center>
                                        </Paper>
                                    )
                                })}
                            </SimpleGrid>
                            
                        )
                    })}

                            <Center mt={20} mb={20}>
                                    <Button radius={28} disabled={image_analysis_amount >= total_read_meters} variant="default" onClick={() => {setImageAnalysisAmount(image_analysis_amount + 2)}}>Load More...</Button>
                                </Center>
                    </>
            ) }
        </>
    ) : (
        <Center>
        <div
className="inline-block h-8 w-8 animate-spin mt-10 rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
role="status">
<span
className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
>
Loading...
</span>
</div>
    </Center>
    )}
    </>
    )
        }
