import { Alert, Button, Center, Checkbox, Drawer, Grid, Group, Stack, Table, Tabs, Text, useMantineTheme } from "@mantine/core";
import { HEADER_HEIGHT } from "../dashboard/layout/header/header.style";
import { useViewportSize } from "@mantine/hooks";
import { useContext, useEffect, useState } from "react";
import axios from "../../utils/axios";
import { AuthContext } from "../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { IconInfoCircle } from "@tabler/icons";
import { Heading } from "@aws-amplify/ui-react";
import { DatePicker } from "@mantine/dates";
import toast from "react-hot-toast";

import * as XLSX from 'xlsx';

function generateExcelWithSheets(data, fromDate, toDate) {
    const monthNames = [
        "January", "February", "March", "April", "May", "June", 
        "July", "August", "September", "October", "November", "December"
    ];

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Format the title
    const title = `Data Summary from ${fromDate} to ${toDate}`;

    // Iterate over the keys in the data object to create sheets
    Object.keys(data).forEach((key) => {
        const sheetData = [];
        // Add the title row with styling
        sheetData.push([title]);
        sheetData.push([]); // Empty row for spacing

        // Add headers and rows
        sheetData.push(["Month", "Total"]);
        let cumulativeTotal = 0;
        data[key].forEach((item) => {
            const year = item._id.year;
            const monthName = monthNames[item._id.month - 1] || "Unknown"; // Convert month number to name
            const monthYear = `${monthName} ${year}`
            cumulativeTotal += item.total;
            sheetData.push([monthYear, item.total]);
        });

        // Add the cumulative total row
        sheetData.push([]);
        sheetData.push(["Cumulative Total", cumulativeTotal]);

        // Create a worksheet from the array
        const sheet = XLSX.utils.aoa_to_sheet(sheetData);

        // Style the title row
        if (!sheet['A1'].s) sheet['A1'].s = {};
        sheet['A1'].s = {
            font: { bold: true, color: { rgb: "FFFFFF" } },
            alignment: { horizontal: "center" },
            fill: { fgColor: { rgb: "1E90FF" } }
        };

        // Style the cumulative total row
        const lastRowIndex = sheetData.length; // Position of the last row
        const cumulativeCell = `A${lastRowIndex}`;
        const valueCell = `B${lastRowIndex}`;

        if (!sheet[cumulativeCell].s) sheet[cumulativeCell].s = {};
        if (!sheet[valueCell].s) sheet[valueCell].s = {};

        const cumulativeStyle = {
            font: { bold: true, color: { rgb: "FFFFFF" } },
            alignment: { horizontal: "center" },
            fill: { fgColor: { rgb: "4682B4" } }
        };

        sheet[cumulativeCell].s = cumulativeStyle;
        sheet[valueCell].s = cumulativeStyle;

        // Add the sheet to the workbook
        XLSX.utils.book_append_sheet(workbook, sheet, key.charAt(0).toUpperCase() + key.slice(1));
    });

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, `Utility-Report-${new Date().getTime()}.xlsx`);
}


export default function Reports(){
    const theme = useMantineTheme();
    const { state, dispatch } = useContext(AuthContext);
    const { height, width } = useViewportSize();
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);

    const [dateRangeFrom, setDateRangeFrom] = useState(new Date(2023, 1, 1))

    const [dateRangeTo, setDateRangeTo] = useState(new Date())

    const [showGenerateReport, setShowGenerateReport] = useState(false);

    const [a, setA] = useState(false);
    const [b, setB] = useState(false);
    const [c, setC] = useState(false);
    const [d, setD] = useState(false);
    const [e, setE] = useState(false);

    const [l, setL] = useState(false);

    const splitURL = (url) => {
        let parts = url.split("/");
        return parts[parts.length - 1];
    }

    useEffect(() => {
        const config = {
            headers: {
                'Authorization': `Bearer ${state.userToken}`
            }
        }
        axios.get('/reports/reports', config).then(function(res){
            if(res.status === 200){
                setReports(res.data.data);
                setLoading(false);
            }
        }).catch(function(error){
            console.log(error);
            setLoading(false);
        });
    }, []);

    const generateReport = () => {

            setL(true);

            const t_id = toast.loading("Generating... please wait");
            
            var items = [];

            if(a){
                items.push("bills");
            }

            if(b){
                items.push("payments");
            }

            if(c){
                items.push("supply");
            }

            if(d){
                items.push("consumption");
            }

            if(e){
                items.push("customers");
            }

            axios.get("/reports/create", {
                headers: {
                    Authorization: `Bearer ${state.userToken}`
                },
                params: {
                    granularity: "monthly",
                    startDate: dateRangeFrom,
                    endDate: dateRangeTo,
                    items: items,
                    parent: state.userData._id
                }
            }).then(function(res){
                if(res.status === 200){
                    toast.success("Your report was generated successfully", {
                        id: t_id
                    });

                    generateExcelWithSheets(res.data, new Date(dateRangeFrom).toDateString(), new Date(dateRangeTo).toDateString());

                    setL(false);
                }
            }).catch(function(err){
                console.log(err);
                toast.error(err?.message || "An issue occured while generating the report.", {
                    id: t_id
                });


                setL(false);
            })
    }
    return (
        <>
        <Alert mt={-20} ml={-20} mr={-15} icon={<IconInfoCircle />} title="Reports">
        Emita provides scheduled reports, both for third-party consumption as well as the general performance of a utility. Third-party users of KPI reports can link their system with Emita to pull data at their own time preference, but at your own consent.
        <br />
        <br />

        <Button onClick={() => {setShowGenerateReport(true)}}>Generate Custom Report</Button>
        </Alert>

        <Drawer padding={"md"} withCloseButton={false} position="right" size={"lg"} withOverlay={false} onClose={() => {setShowGenerateReport(false)}} opened={showGenerateReport}>
            <Stack justify="space-between" h={height * 0.9}>
                <div>
                <Heading level={6} marginBottom={20} fontWeight={650} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Generate Custom Report</Heading>
                <Text mb="md">Select all the categories that you need to generate a report.</Text>
                <Checkbox checked={a} onChange={(e) => {setA(e.currentTarget.checked)}} label="Bills" mb="md" />
                <Checkbox checked={b} onChange={(e) => {setB(e.currentTarget.checked)}} label="Payment Collections" mb="md" />
                <Checkbox checked={c} onChange={(e) => {setC(e.currentTarget.checked)}} label="Supply" mb="md" />
                <Checkbox checked={d} onChange={(e) => {setD(e.currentTarget.checked)}} label="Consumption" mb="md" />
                <Checkbox checked={e} onChange={(e) => {setE(e.currentTarget.checked)}} label="Customers" mb="md" />

                <Text mb="md">Date Range</Text>

                <DatePicker value={dateRangeFrom} onChange={setDateRangeFrom} mb="md" dropdownType="modal" placeholder="Pick date" label="Date From" />

                <DatePicker value={dateRangeTo} onChange={setDateRangeTo} mb="md" dropdownType="modal" placeholder="Pick date" label="Date To" />

                </div>

                <div>
                    <Group>
                        <Button onClick={() => {setShowGenerateReport(false)}} variant="default">Cancel</Button>
                        <Button loading={l} onClick={() => {generateReport()}}>Generate</Button>
                    </Group>
                </div>
            </Stack>
        </Drawer>

        <Tabs defaultValue={"summarized"}>
            <Tabs.List>
                <Tabs.Tab value="summarized">
                    Summarized Reports
                </Tabs.Tab>

                <Tabs.Tab value="kpi">
                    KPI Reports
                </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="summarized">
            <Grid>
            <Grid.Col sm={12} lg={12}>            
            {reports.length === 0 && !loading ? (
                <>
                                <Center mt={"15%"}>
                    <img src={require("../../assets/waiting_hourglass.png")} />
                </Center>

            <Text align="center" mt={30}>Reports once ready will appear here.</Text>
                </>
            ) : reports.length === 0 && loading ? (
                <Center mt={"15%"}>
                    <FontAwesomeIcon icon={faSpinner} spin />
                </Center>
            ) : (
                <Table fontSize={"xs"} striped>
                    <thead>
                        <tr>
                            <th>Report</th>
                            <th>Period</th>
                            <th>Category</th>
                            <th>Created At</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reports.map((item, idx) => {
                            return (
                                <tr key={`report-${idx}`}>
                                    <td>
                                        <a href={item.path}>/{splitURL(item.path)}</a>
                                    </td>
                                    <td>{item.reportFor}</td>
                                    <td>{`${item.category} Report`}</td>
                                    <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                                    <td>
                                        <Button component="a" href={item.path} size="xs">Download</Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            )}
</Grid.Col>
        </Grid>
            </Tabs.Panel>

            <Tabs.Panel value="kpi">
            <Stack justify="center" h={(height - HEADER_HEIGHT) * 0.85}>
            <Center>
            <img src={require("../../assets/waiting_hourglass.png")} />
            </Center>
            <Text align="center" mt={20}>KPI reports will appear here.</Text>
        </Stack>
            </Tabs.Panel>
        </Tabs>
        </>
    )
}